import React, { useEffect, useState } from 'react';
import ProductCard from '../../constants/ProductCard';
import axiosInstance from '../../services/api';
import { ADMIN } from '../../services/admin.service';
import WishListLoader from '../wishlist/WishlistLoader';
import Nodata from '../../constants/Nodata';
import styles from "./Recentview.module.scss";
import { useTranslation } from 'react-i18next';


const RecentlyViewed = () => {
    const {t} = useTranslation();
    const laung = localStorage.getItem("laung")
    const [recentProdcuts, setViewProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const handlegetRecentView = () => {
        axiosInstance.post("product", { "search": "search_history", "size":'20' })
            .then(res => {
                if (res.data.status) {
                    setIsLoading(false)
                    setViewProducts(res.data.response);
                }
            })
            .catch(e => { console.log(e.message, "enquiry error"); setIsLoading(false); ADMIN.toast.error(e.message) })
    }

    useEffect(() => {
        handlegetRecentView()
    }, []);

    return (
        <div className={styles.cart_details} >
            < p 
                className={styles.heading}
                style={{fontFamily: laung == "ar" ? "Cairo-Regular":"Poppins-Regular"}}
                > {t("Recently Viewed")}
            </p >

            <div className={styles.recent_container} >
                {
                    isLoading ?
                        <WishListLoader /> :
                        recentProdcuts.product_list.length != 0 ?
                           Array.isArray(recentProdcuts.product_list) && recentProdcuts.product_list.map((product, indi) => (
                                <ProductCard
                                    key={indi}
                                    details={product}
                                />
                            ))

                            : <Nodata />
                }

            </div>
        </div>

    )
}

export default RecentlyViewed
