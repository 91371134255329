import React, { useState } from 'react';
import styles from "./ProductCard.module.scss";
import { useNavigate, useParams } from 'react-router-dom';
import { AddToCart, AddToWishList } from '../services/Product.service';
import { ADMIN } from '../services/admin.service';
import { useDispatch } from 'react-redux';
import { fetchHomepageDetails } from '../redux/slice/departmentSlice';
import { menuCounts } from '../redux/slice/menuCountSlice';
import { useTranslation } from 'react-i18next';
import CartIcon from "../assets/Images/svgs/shop_cart.svg";
import Rating from "../assets/Images/svgs/goldernstar.svg";
import wishlistborder from '../assets/Images/svgs/wishlist_border.svg';
import wishlistbackground from '../assets/Images/svgs/wishlist_background.svg';


const ProductCard = ({
    details,
    type
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const { departmentID } = params;
    const [wishListStatus, setWishListStatus] = useState(false);
    const { t } = useTranslation();
    const laung = (localStorage.getItem('laung'));
    const [disbleCart, setDisableCart] = useState(false)
    const [addtoWish, setAddToWish] = useState(details.wishlist)

    const handleDisable = () => {
        setTimeout(() => {
            setDisableCart(false)
        }, 2000)
    }

    const addToKart = (product) => {
        const data = { "product_id": product.product_id, "quantity": product.minimum_quantity }
        AddToCart(data)
            .then(res => {
                if (res.data.status) {
                    handleDisable()
                    dispatch(menuCounts())
                    ADMIN.toast.success(res.data.response.message)
                } else {
                    handleDisable()
                    ADMIN.toast.error(res.data.response.message)
                }

            })
            .catch(error => {
                handleDisable()
                if (error.response.status == "503") {
                    navigate(laung == 'en' ? `/${laung}/login` : "/login")
                    localStorage.setItem("productID", product.product_id);
                    localStorage.setItem("productQuantity", product.minimum_quantity);
                }
            })
    };

    const handleAddToWishList = (stat) => {

        setDisableCart(true)
        if(stat == 1) {
            setAddToWish(1)
        } else {setAddToWish(0)}
        AddToWishList(details.product_id, stat)
            .then((res => {
                if (res.data.status) {
                    dispatch(fetchHomepageDetails(departmentID))
                    // dispatch(menuCounts())
                    setWishListStatus(!wishListStatus)
                    ADMIN.toast.success(res.data.response.message)
                } else {
                    ADMIN.toast.warn(res.data.response.message)
                }
            }))
            .catch(e => {
                if (e.response.status == "503") {
                    navigate(laung == 'en' ? `/${laung}/login` : "/login")
                    localStorage.setItem("productID", details.product_id);
                    localStorage.setItem("stat", stat);
                }
            })
    };


    const handleNavigteProductDetails = (product) => {
        const arbProductURL = `/${product.url == "" ? "p" : encodeURIComponent(product.url)}/p/${product.product_id}`
        const enProductURL = `/${laung}/${product.url == "" ? "p" : encodeURIComponent(product.url)}/p/${product.product_id}`
        navigate(laung == "en" ? enProductURL : arbProductURL, {
            state: {
                search: 0
            }
        })
    };
    
    return (
        <div className={type === "-1" ? styles.flash_container : styles.container}>
            < div className={details.available_quantity !== "0" ? styles.product_card : styles.product_disabled}>
                <button className={laung == "ar" ? styles.ar_wish_list : styles.wish_list} onClick={() => handleAddToWishList( addtoWish == 1 ? 2 :1 )}>
                    {addtoWish == 1 ? <img src={wishlistbackground} alt="" /> :
                        <img src={wishlistborder} alt="" />}
                </button>
                <a
                    href={laung == "en" ? `/${laung}/${details.url == "" ? "p" : encodeURIComponent(details.url)}/p/${details.product_id}` :
                        `/${details.url == "" ? "p" : encodeURIComponent(details.url)}/p/${details.product_id}`
                    } target="_blank" >

                    <div className={styles.product_pic}>
                        {details.discount_value !== "0.00" &&
                            <p className={laung == "ar" ? styles.offer_tag_arabic : styles.offer_tag} >
                                {`${t("off")} ${t("extra")} ${details.discount_value}  ${details.discount_type === "1" ? t("SAR") : details.discount_type === "2" ? "%" : ""}   `}
                            </p>
                        }
                        <img
                            src={details.image}
                            alt={details.alt_text}
                        />
                    </div>
                    <div className={laung == "ar" ? styles.ar_name_price : styles.name_price} >
                        < p className={styles.product_name} style={{ fontFamily: laung == "ar" ? "Cairo-Semibold" : "Karla-Bold" }} > {details.title.slice(0, 50)} </p>
                        <div style={{ minHeight: '50px' }}>
                            {
                                details.min_price === details.max_price ?
                                    <div className={laung == "ar" ? styles.ar_min_max_prices : styles.min_max_prices} >
                                        {details.discount !== "0.00" ?
                                            <div>
                                                <div className={styles.actual_price_height} style={{ display: "flex", flexWrap: "wrap",  flexDirection:'column' }}>
                                                    <span className={styles.actual_price} style={{ fontFamily: laung == "ar" ? "Cairo-Semibold" : "Poppins-SemiBold" }}>{details.currency} {(details.min_price - details.discount).toFixed(2)}</span>
                                                    <span className={styles.actual_price1} style={{ fontFamily: laung == "ar" ? "Cairo-Semibold" : "Poppins-Medium", textDecorationLine: "line-through", color: "#787878" }}>{details.currency} {details.min_price}</span>
                                                </div>
                                                <span className={styles.tax_include} style={{ fontFamily: laung == "ar" ? "Cairo-Semibold" : "Poppins-Medium" }}>{t("Prices include added tax")}</span>
                                            </div>
                                            :
                                            <div>
                                                <span className={styles.actual_price} style={{ fontFamily: laung == "ar" ? "Cairo-Semibold" : "Poppins-SemiBold" }}>{details.currency} {details.min_price}</span>
                                                <p className={styles.tax_include} style={{ fontFamily: laung == "ar" ? "Cairo-Semibold" : "Poppins-Medium" }} >{t("Prices include added tax")}</p>
                                            </div>
                                        }
                                    </div> :
                                    <div className={laung == "ar" ? styles.ar_min_max_prices : styles.min_max_prices} >
                                        {
                                            details.discount !== "0.00" ?
                                                <div className={styles.actual_price_height}>
                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                        <span className={styles.actual_price} style={{ fontFamily: laung == "ar" ? "Cairo-Semibold" : "Poppins-SemiBold" }}>{details.currency} {(details.min_price - details.discount).toFixed(2)} - {details.currency} {(details.max_price - details.max_discount).toFixed(2)}</span>
                                                        <span className={styles.actual_price1} style={{ fontFamily: laung == "ar" ? "Cairo-Semibold" : "Poppins-Medium" , textDecorationLine: "line-through", color: "#787878" }}>{details.currency} {details.min_price} - {details.currency} {details.max_price}</span>
                                                    </div>
                                                    <span className={styles.tax_include} style={{ fontFamily: laung == "ar" ? "Cairo-Semibold" : "Poppins-Medium" }}>{t("Prices include added tax")}</span>
                                                </div>
                                                :
                                                <div>
                                                    <span className={styles.actual_price} style={{ fontFamily: laung == "ar" ? "Cairo-Semibold" : "Poppins-SemiBold" }}>{details.currency} {details.min_price} - {details.currency} {details.max_price}</span>
                                                    <p className={styles.tax_include} style={{ fontFamily: laung == "ar" ? "Cairo-Semibold" : "Poppins-Medium" }}>{t("Prices include added tax")}</p>
                                                </div>
                                        }
                                    </div>
                            }
                        </div>
                    </div>
                    <div className={styles.ratings_country} >
                      {details.rating != 0 && details.rating_count != 0 && <p style={{ fontFamily: laung == "ar" ? "Cairo-Semibold" : "Poppins-SemiBold" }} >
                            <img
                                src={Rating}
                                alt="Rating"
                            />
                            {details.rating} ({details.rating_count})
                        </p>}
                        <p style={{ fontFamily: laung == "ar" ? "Cairo-Semibold" : "Poppins-SemiBold" }}>{details.vendor_city}</p>
                    </div>
                </a>



                <button disabled={disbleCart} className={details.available_quantity !== "0" ? styles.add_to_cart : styles.disabled} onClick={() => addToKart(details)} >
                    <img src={CartIcon} alt="add to cart" style={{ width: '22px', height: '22px' }} />
                    <span className={styles.add_to_cart_text} style={{ fontFamily: "Cairo-Semibold" }} >{details.available_quantity !== "0" ? t("add to cart") : t("out of stock")}</span>
                </button>
            </div>
        </div>
    )
}

export default ProductCard