import React, { useState } from 'react'
import LandingHeaderThree from '../Components/LandingThree/LandingHeaderThree/LandingHeaderThree'
import LatestFooter from '../Components/Home/Footer/LatestFooter';

const WithHeaderRoutes = ({ children }) => {
    const [theIndustry, setIndutry] = useState("");

    return (
        <div>
            <header><LandingHeaderThree setIndutry={setIndutry} /></header>
            <div className='body'>
                {children}
            </div>
            <footer>
                <LatestFooter />
            </footer>
        </div>
    )
}

export default WithHeaderRoutes
