import React from 'react';
import styles from "./SingleOrder.module.scss";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Default from "../../../../assets/Images/pngs/Default.png";
import CryptoJS from 'crypto-js';


const SingleOrder = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const laung = (localStorage.getItem('laung'));


  const allOrdersDetail = useSelector((state) => state?.allOrderDetails?.data?.response);

  const encryptData = (data) => {
    const secretKey = 'your-secret-key'; // Replace with a secure key
    return CryptoJS.AES.encrypt(data.toString(), secretKey).toString();
  };

  const NavigateOrderDetails = (order) => {
    const encryptedId = btoa(order?.order_vendor_id.toString());
    const secondCode = btoa(encryptedId)
    // navigate(laung == "en" ? `/${laung}/account/order_status/${encodeURIComponent(encryptedId)}` : `/account/order_status/${encodeURIComponent(encryptedId)}`)
    navigate(laung == "en" ? `/${laung}/account/order_status/${secondCode}` : `/account/order_status/${secondCode}`)
  };

  function formadivate(dateString) {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
  }

  const OrderPictures = (images) => {
    return (
      images.map((pic, picindi) => (images.length == 1 ?
        <img key={picindi} src={pic.image} alt="Product Picture" />
        :
        images.length == 2 ?
          <div key={picindi} className={styles.four_images1}>
            <img key={picindi} src={pic.image} alt="Product Picture" />
          </div> :
          (images.length == 3 || images.length == 4) ?
            <div key={picindi} className={styles.four_images}>
              <img key={picindi} src={pic.image} alt="Product Picture" />
            </div> :
            picindi < 3 && <div key={picindi} className={styles.four_images}>
              <img key={picindi} src={pic.image} alt="Product Picture" />
            </div>
      ))
    )
  }

  return (
    <div>
      <div className={laung == "ar" ? styles.ar_tables : styles.tables}>
        <div className={styles.table_sec}>
          <div className={styles.table_tbody}>
            {allOrdersDetail && allOrdersDetail.map((order, indi) => {
              return (
                <div className={styles.table_tr} key={indi} onClick={() => NavigateOrderDetails(order)}>
                  <div className={styles.first_table}>
                    {order.status_title && <h5 style={{
                      backgroundColor: order.status == "105" ? "#456ED610" :
                        order.status == "100" ? "#93c9af99" :
                          order.status == "91" ? "#958559" : "#FF3D0010",
                      color: order.status == "105" ? "#958559" :
                        order.status == "100" ? "#1F965D" :
                          order.status == "91" ? "#FFFFFF" : "#FF3D00",
                      fontFamily: laung == "ar" ? "Cairo-Medium" : "Poppins-Medium"
                    }} >{order.status_title}</h5>}
                    {order.created_on && <p>{formadivate(order.created_on)}</p>}
                    {order.order_number && <p>ID:{order.order_number}</p>}
                  </div>
                  <div className={styles.second_table}>
                    <div className={styles.second_table_left}>
                      <div className={styles.table_img}>
                        {OrderPictures(order.order_image)}
                        {order.order_image.length > 4 &&
                          <div className={styles.four_sapn}><span>+ {order.order_image.length - 3}</span></div>}
                        {order.order_image.length == 0 && <img src={Default} alt="venderlane" />}
                      </div>
                      <div className={styles.table_bottom}>
                        <h4>{order.products}</h4>
                        <div className={styles.currency_sec}>
                          {order.counts && <p style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "Poppins-Regular" }}> {order.counts} {t('items')}</p>}
                          <span>-</span>
                          {order.grand_total && <p> {order.grand_total} {order.currency} </p>}
                        </div>
                      </div>
                    </div>
                    <div className={styles.second_table_right}>
                      <h2><span style={{ fontFamily: laung == "ar" ? "Cairo-Medium" : "Poppins-Medium" }}>{t("total")}</span> {order.grand_total} {order.currency}</h2>
                    </div>
                  </div>
                  <div className={styles.third_table}>
                    <button style={{ fontFamily: laung == "ar" ? "Cairo-Medium" : "Poppins-Medium" }}>{t("Review")}</button>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>

  )
}

export default SingleOrder