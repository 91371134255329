import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../services/api";

export const fetchAllOrderDetails = createAsyncThunk("allOrdersDetail", async (orderdata) => {
  const response  = await axiosInstance.get(
    `get_order?index=${orderdata.IND}&size=${50}&search=${orderdata.orderSearch}&filter=${orderdata.filtertype}&status=${orderdata.status}&pending_rating=${orderdata.pendingRating}&from_date=${orderdata.date}&to_date=${orderdata.date}`)
  return response.data
});

const allOrderDetailsSlice = createSlice({
  name:"AllOrderDetails",
  initialState:{
    isLoading:false,
    data:null,
    isError:false
  },

  extraReducers: (builder) => {
    builder.addCase(fetchAllOrderDetails.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(fetchAllOrderDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data  = action.payload;
    });

    builder.addCase(fetchAllOrderDetails.rejected, (state, action) => {
      state.isError = true;
    })
  }
});


export default allOrderDetailsSlice.reducer;