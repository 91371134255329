import React, { useEffect, useState } from 'react'
import styles from "./AboutUs.module.scss";
import { getAboutUsDetails } from '../../services/Homepage.service';
import BannerFlower from "../../assets/Images/pngs/banner_flower.png";
import termsbanner from '../../assets/Images/svgs/terms_condition.svg';
import AboutUsLoader from '../../constants/PreLoaders/AboutUsLoader';
import { useTranslation } from 'react-i18next';


const AboutUs = () => {
  const laung = (localStorage.getItem('laung'));
  const [aboutDetails, setAboutDetails] = useState("");
  const { t } = useTranslation();


  useEffect(() => {
    getAboutUsDetails("about")
      .then(res => {
        if (res.data.status) {
          setAboutDetails(res.data.response)
        }
      })
      .catch(err => console.error(err, "about us error"))
  }, []);


  return (
    <div className={styles.container} >
      <div className={styles.header_img} >

        <img src={termsbanner} className={styles.termsbanner} alt="" />
        <div className={styles.terms_heading} >
          <h1 style={{ fontFamily: laung == "ar" ? "Cairo-Medium" : "Poppins-Medium" }}>{t('About Venderlane')}</h1>
          <p style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "Poppins-Regular" }}>{t('B2B MARKETPLACE')}</p>
        </div>
      </div>
      {
        aboutDetails ?
          <div className={styles.body} >
            {/* <div className={styles.pic_section} >
              <img src={BannerFlower} alt="flower" className={laung == "ar" ? styles.flower_one_arabic : styles.flower_one} />
              <img className={styles.section_pic} src={aboutDetails.image} alt={aboutDetails.title} title={aboutDetails.title} />
            </div> */}
            <div className={styles.desc_section} >
              <h1 className={styles.title} style={{ fontFamily: laung == "ar" ? "Cairo-Bold" : "Avenir_Heavy" }} >{aboutDetails.title}</h1>
              <h6 className={styles.sub_tilte} style={{ fontFamily: laung == "ar" ? "Cairo-Semibold" : "Poppins-Medium" }} >{aboutDetails.sub_title}</h6>
              <p className={styles.description} dangerouslySetInnerHTML={{ __html: aboutDetails.description }} style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "Poppins-Medium" }} />
            </div>
          </div> :
          <AboutUsLoader />
      }
      <footer>
      </footer>
    </div>
  )
}

export default AboutUs