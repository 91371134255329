import React, { useEffect } from 'react'
import { fetchAllOrderDetails } from '../../redux/slice/allOrderSlice';
import styles from "./PendingReviews.module.scss"
import { useDispatch, useSelector } from 'react-redux';
import EmptyOrders from '../../constants/EmptyOrders';
import SingleOrder from '../Profile/orders/singleorder/SingleOrder';
import { useTranslation } from 'react-i18next';

const PendingReviews = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const laung = localStorage.getItem("laung")

    useEffect(() => {
        dispatch(fetchAllOrderDetails({ IND: 0, orderSearch: "", filtertype: "", status: 0, pendingRating: "1", }))
    }, []);

    const allOrdersDetail = useSelector((state) => state?.allOrderDetails?.data?.response);
    return (
        <div className={styles.container} >
            <h1 className={styles.heading}
                style={{ fontFamily: laung == "ar" ? "Cairo-SemiBold" : "Poppins-SemiBold" }}
            > {t("Review Orders")}
            </h1 >
            {
                allOrdersDetail && allOrdersDetail.length != "0" ?
                    <SingleOrder />
                    : <EmptyOrders />
            }
        </div>
    )
}

export default PendingReviews;
