import React, { useEffect, useState } from 'react';
import styles from "./TermsandCondition.module.scss";
import { useSelector } from 'react-redux';
import { H1 } from '../../constants/CustomText/VendorHeading';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import TheFaqs from "./FAQ";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsPic from "../../assets/Images/svgs/termsandcondition.png";
import termsbanner from '../../assets/Images/svgs/terms_condition.svg';

const TermsConditions = () => {
  const termsCondtions = useSelector((state) => state?.termsandPrivacy.data?.response);
  const laung = (localStorage.getItem('laung'));
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const [activeTab, setActive] = useState('1');

  useEffect(() => {
    setActive(params.type)
  }, [params.type])

  const List = [
    {
      id: 1,
      title: t("vterms&condition"),
    },
    {
      id: 2,
      title: t("privacy policy"),
    },
    {
      id: 3,
      title: t("faq"),
    },
  ];
  const handelNavigate = (term) => {
    setActive(term.id)
    navigate(laung == "en" ? `/${laung}/terms_conditions/${term.id}` : `/terms_conditions/${term.id}`)
  }

  return (
    <div className={styles.container} >
      <div className={styles.header_img} >
        <img src={termsbanner} className={styles.termsbanner} alt="" />
        <header className={styles.header_details}>
          <div className={styles.terms_heading} >
            <h1 style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "Poppins-SemiBold" }} >{params.type == "2" ? t("vender privacy") : params.type == "3" ? t("faq") : t("vterms&condition")}</h1>
          </div>
        </header>
      </div>
      <div className={styles.terms_policy_faq} >
        {
          List.map((term, indi) => (
            <button
              key={indi}
              onClick={() => handelNavigate(term)}
              style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "Poppins-SemiBold" }}
              className={term.id == params.type ? styles.active_button : styles.inactive_button} >{term.title}</button>))
        }
      </div>



      {activeTab == "3" && <TheFaqs />}
      {activeTab == "2" && <PrivacyPolicy />}
      <div className={styles.terms_body} >
        {activeTab == "1" && termsCondtions && <div className={styles.body} dangerouslySetInnerHTML={{ __html: termsCondtions.terms_condition }}
          style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "Poppins-Regular", color: '#666666' }} />}
        <div className={styles.ready_to_try} >
          <h1
            style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "Poppins-Regular" }}
          >
            {t("Ready To Try Venderlane ?")}
          </h1>
          <span style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "Poppins-Regular" }} >{t("YOUR GROWTH PARTNER")}</span>
          <button
            style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "Poppins-Regular" }}
            onClick={() => navigate(laung == "en" ? `/${laung}/registration` : `/registration`)} >{t("Sign Up")}</button>
        </div>
      </div>
    </div>
  )
}

export default TermsConditions;