import React, { useEffect, useState } from 'react';
import styles from "./ForgotPassword.module.scss";
import { useLocation, useNavigate } from 'react-router-dom';
import loginbanner from '../../../assets/Images/pngs/login_banner.png';
import VenderlaneLogo from '../../../assets/Images/svgs/venderlane_logo_1.svg';
import { useFormik } from 'formik';
import * as yup from "yup";
import { useTranslation } from 'react-i18next';
import Spinner from '../../../constants/spinner/Spinner';
import { ADMIN } from '../../../services/admin.service';
import axiosInstance from '../../../services/api';



const CreateNewPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation()
  const laung = (localStorage.getItem('laung'));
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    password: "",
    confirm_password: "",
  }

  const passwordValidation = yup.object({
    password: yup
      .string()
      .required(t("Password is Required"))
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, t("must contain")),
    confirm_password: yup
      .string()
      .required(t("confirm passowrd is required"))
      .oneOf([yup.ref("password"), null], t("passwords are not matching")),
  });

  const formik = useFormik({
    initialValues,
    onSubmit: values => handleVerifyOtp(),
    validationSchema: passwordValidation
  })

  const handleVerifyOtp = () => {
    setIsLoading(true)
    axiosInstance.post("change_password", { "password": formik.values.password, "token": state.token })
      .then(res => {
        if (res.data.status) {
          localStorage.setItem("venlogtoken", res.data.response.token);
          localStorage.setItem("departmentID", res.data.response.department_id)
          localStorage.setItem("categoryname", res.data.response.url)
          setIsLoading(false)
          navigate(laung == 'en' ? `/${laung}` : "/", { replace: true })
          ADMIN.toast.success(res.data.response.message)
        } else {
          ADMIN.toast.warn(res.data.response.message)
          setIsLoading(false)
        }
      })
      .catch(e => { console.log(e.message); ADMIN.toast.error(e.message); setIsLoading(false) })
  }


  return (
    <>
      <div className={laung == "ar" ? styles.ar_login_banner : styles.login_banner}>
        <img src={loginbanner} className={styles.banner_img} alt="" />
        <div className={styles.login_header}
          onClick={() => navigate(laung == 'en' ? `/${laung}` : "/", { replace: true })}
        >
          <img src={VenderlaneLogo} alt="" className={styles.venderlane_logo} />
        </div>
      </div>
      <div className={laung == "ar" ? styles.ar_container : styles.container} >
        <div className={styles.login_form_container}>

          <div className={styles.body} >
            <div className={styles.login_form}>
              <h1 style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }}  >{t("new Password")}</h1>
              <p style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }} >{t("Please create a new password that you don’t use on any other site.")}</p>
              <form onSubmit={formik.handleSubmit} >
                <div className={styles.form_group}>
                  <input
                    name="password"
                    id="password"
                    className={styles.input}
                    onChange={formik.handleChange}
                    type="text"
                    style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }}
                    placeholder={t("enter new password")}
                  />
                  {(formik.errors.password && formik.touched.password) &&
                    <span className={styles.error_message}
                      style={{
                        fontFamily: laung == "ar" ? "Cairo-Regular"
                          : "inter-Regular"
                      }} >
                      {formik.errors.password}</span>}
                </div>
                <div className={styles.form_group}>
                  <input
                    name="confirm_password"
                    id="confirm_password"
                    onChange={formik.handleChange}
                    className={styles.input}
                    type="password"
                    style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }}
                    placeholder={t("confirm password")}
                  />
                  {(formik.errors.confirm_password && formik.touched.confirm_password) &&
                    <span className={styles.error_message}
                      style={{
                        fontFamily: laung == "ar" ? "Cairo-Regular"
                          : "inter-Regular"
                      }} >
                      {formik.errors.confirm_password}</span>}
                </div>
                <button
                  type='submit'
                  disabled={isLoading}
                  className={styles.login_button}
                  style={{
                    fontFamily: laung == "ar" ? "Cairo-Regular"
                      : "inter-Regular"
                  }}
                >
                  {isLoading ? <Spinner /> : t("update password")}
                </button>
              </form>
              <p className={styles.protect_p}
                style={{
                  fontFamily: laung == "ar" ? "Cairo-Regular"
                    : "inter-Regular"
                }}
              >{t("Just remember?")} <span onClick={() => navigate(laung == "en" ? `/${laung}/registration` : `/registration/`)}>{t("Sign in")}</span></p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CreateNewPassword