import React, { useState } from 'react';
import styles from "./Products.module.scss";
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Sort from "./assets/sort.svg";
import Filter from "./assets/filter.svg";
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';

import Filters from './Filters';


const Products = ({
  selectedSubCat,
  setSelectedSubcat,
  sortID,
  setSortID,
  productCount,
  setPageNumber,
  setVariationValues,
  setPriceRange,
  handleNotScrollForFilter,
  clearFilter,
  variationValues,
  setSelectedBrands,
  selectedBrands,
  setOrderRatings,
  orderRatings,
  selectedCities,
  setSelectedCities,
  setMaxPrice,
  setMinPrice,
  categories,
  setProductLoader,
}) => {

  const dispatch = useDispatch()

  const params = useParams();
  const { catID, categoryname, subcategory } = params;

  const { t } = useTranslation();
  const laung = localStorage.getItem("laung");

  const [filterDrawer, setFilterDrawer] = useState(false);
  const [sortDrawer, setSortDrawer] = useState(false)

  const sortinArr = [
    {
      "id": 1,
      "title": t("new")
    },
    {
      "id": 8,
      "title": t("discount")
    },
    {
      "id": 7,
      "title": t("most rated")
    },
    {
      "id": 2,
      "title": t("old")
    },
    {
      "id": 5,
      "title": t("most ordered")
    },
    {
      "id": 6,
      "title": t("stock available")
    },
    {
      "id": 3,
      "title": t("hightolow")
    },
    {
      "id": 4,
      "title": t("lowtohigh")
    },
    {
      "id": 9,
      "title": t("most viewed")
    }
  ];

  const handleSorting = (sorts) => {
    setProductLoader(true)
    setSortID(() => sorts.id)
    setPageNumber(0)
    if (sortID == sorts.id) { setSortID("") }
    handleCloseSortDrawer()
  };

  const handleOpenSortDrawer = () => {
    setSortDrawer(true)
  };

  const handleCloseSortDrawer = () => {
    setSortDrawer(false)
  }

  return (
    <div className={styles.container} >
      <div className={styles.products_number}>
         <p className={styles.total_products} style={{ fontFamily: laung == "ar" ? "Cairo-Medium" : "Poppins-Medium" }} >{productCount} {t("listings")}</p>

        <div className={styles.header_right} >
          <p onClick={() => handleOpenSortDrawer()} style={{ fontFamily: laung == "ar" ? "Cairo-Medium" : "Poppins-Medium" }} ><img src={Sort} alt={t("sort")} />{t("sort")}</p>
          <p onClick={() => setFilterDrawer(true)} style={{ fontFamily: laung == "ar" ? "Cairo-Medium" : "Poppins-Medium" }} ><img src={Filter} alt={t("filters")} />{t("filters")}</p>
        </div>
      </div>
      {<Drawer
        anchor={laung == "ar" ? "right" : "left"}
        open={sortDrawer}
        onClose={() => setSortDrawer(false)}
      >
        <div className={styles.the_filter_drawer} >
          <Box>
            {
              <div className={styles.sorting_data} >
                <p className={styles.sort_heading} style={{ fontFamily: laung == "ar" ? "Cairo-Bold" : "Avenir Black", paddingTop: laung == "ar" ? "90px" : "0px", fontWeight: "bolder" }}>{t("sort")}</p>
                <div className={styles.sorting_list} >
                  {
                    sortinArr.map((sorts, indi) => {
                      return (
                        <p
                          onClick={() => handleSorting(sorts)}
                          key={indi}
                          style={{
                            fontFamily: laung == "ar" ? "Cairo-Medium" : "Poppins-Medium",
                            borderBottom: sortID === sorts.id ? "2px solid #2A2821" : "1px solid #D9CBA0",
                            borderTop: sortID === sorts.id ? "2px solid #2A2821 " : "1px solid #D9CBA0",
                            fontWeight: sortID === sorts.id ? "bold" : "500",
                            color: sortID === sorts.id ? "#2A2821" : "#9A9071"
                          }}
                        >{sorts.title}</p>
                      )
                    })
                  }
                </div>
              </div>
            }
          </Box>
        </div>
      </Drawer>}
      <Drawer
        anchor={laung == "ar" ? "right" : "left"}
        open={filterDrawer}
        onClose={() => setFilterDrawer(false)}
      >
        <Filters
          categories={categories}
          setPageNumber={setPageNumber}
          setSelectedSubcat={setSelectedSubcat}
          handleNotScrollForFilter={handleNotScrollForFilter}
          setPriceRange={setPriceRange}
          setVariationValues={setVariationValues}
          setFilterDrawer={setFilterDrawer}
          clearFilter={clearFilter}
          selectedSubCat={selectedSubCat}
          variationValues={variationValues}
          setSelectedBrands={setSelectedBrands}
          selectedBrands={selectedBrands}
          setOrderRatings={setOrderRatings}
          handleSorting={handleSorting}
          orderRatings={orderRatings}
          selectedCities={selectedCities}
          setSelectedCities={setSelectedCities}
          setMaxPrice={setMaxPrice}
          setMinPrice={setMinPrice}
          setSortID={setSortID}
          setProductLoader={setProductLoader}
        />
      </Drawer>
    </div>
  )
}

export default Products;