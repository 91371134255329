import React from 'react';
import ContentLoader from 'react-content-loader'

const WishListLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 400 160"
      backgroundColor="#ede3e3"
      foregroundColor="#e5dcc8"
    >
      <rect x="10" y="6" rx="4" ry="4" width="382" height="30" />
      <rect x="10" y="40" rx="4" ry="4" width="382" height="30" />
      <rect x="10" y="74" rx="4" ry="4" width="382" height="30" />
      <rect x="10" y="108" rx="4" ry="4" width="382" height="30" />
    </ContentLoader>
  )
}

export default WishListLoader