import axiosInstance from "./api/index";

export const productDetails = async (pID, search, kartadded) => {
  try {
    const response = await axiosInstance.get(`product?product_id=${pID}&search=${search}&added_cart=${kartadded}`)
    return response
  } catch (error) {
    throw (error)
  }
};

export const AddToCart = async (data) => {
  try {
    const response = await axiosInstance.post("add_cart", data)
    return response
  } catch (error) {
    throw (error)
  }
};

export const AddToWishList = async (pID, status) => {
  try {
    const response = await axiosInstance.get(`add_wishlist?product_id=${pID}&status=${status}`)
    return response;
  } catch (error) {
    throw (error)
  }
};

export const getSimilarProducts = async (pID) => {
  try {
    const response = await axiosInstance.get(`related_product?product_id=${pID}`)
    return response;
  } catch (error) {
    throw (error)
  }
};

export const moveWishToKart = async (PID, stat) => {
  try {
    const response = await axiosInstance.get(`wishlist_to_cart.php?product_id=${PID}&status=${stat}`)
    return response
  } catch (error) {
    throw (error)
  }
};

export const getProductList = async (data) => {
  try {
    const response = await axiosInstance.post("product", data)
    return response;
  } catch (error) {
    throw (error)
  }
};

export const getFilters = async (dID, catID) => {
  try {
    const response = await axiosInstance.get(`filter`)
    // const response = await axiosInstance.get(`filter?department_id=${dID}&category_id=${catID}`)
    return response;
  } catch (error) {
    throw (error)
  }
}