import React from 'react';
import styles from "./Nodata.module.scss";
import BannerFlower from "../assets/Images/pngs/banner_flower.png";
import Flower from "../assets/Images/svgs/flower.svg";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NoInfo from "../assets/Images/svgs/not_found.svg";


const EmptyOrders = () => {
  const navigate = useNavigate()
  const {t} = useTranslation();
  const laung = localStorage.getItem("laung");

  const handleNavigate = () => {
    navigate(laung == "en" ? `/${laung}` : "/")
  };
  
  const goBack = () => {
    if (window.history.state === null || window.history.state.url === '_black' || window.history.state.idx == 0) {
        navigate(laung == 'en' ? `/${laung}` : "/");
    } else {
       navigate(laung == 'en' ? `/${laung}` : "/");
    }
}

    return (
        <div className={styles.container} >
            <img src={NoInfo} alt="" />
            <p className={styles.heading} style={{ fontFamily: laung == "ar" ? "Cairo-Bold" : "Poppins-Medium" }} >{t("No Orders Found")}</p>
            <button className={styles.go_back} onClick={() => handleNavigate()} style={{ fontFamily: laung == "ar" ? "Cairo-Semibold" : "Poppins-Medium" }} >{t("go back")}</button>
        </div>
    )
}

export default EmptyOrders;