import React, { useState } from 'react'
import styles from "./ForgotPassword.module.scss";
import CustomButton from '../../../shared/Button/CustomButton'
import loginbanner from '../../../assets/Images/pngs/login_banner.png';
import VenderlaneLogo from '../../../assets/Images/svgs/venderlane_logo_1.svg';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../services/api';
import { ADMIN } from '../../../services/admin.service';
import OtpInput from 'react-otp-input';
import { useFormik } from 'formik';
import * as yup from "yup";
import { useTranslation } from 'react-i18next';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const laung = (localStorage.getItem('laung'));

  const [otpSent, setOTPSent] = useState(false);

  const initialValues = {
    email_id: "",
    otp: "",
  }

  const schemaValidation = yup.object({
    email_id: yup
      .string()
      .email(t("enter a Valid mail address"))
      .test('email-validation', t("enter a Valid mail address"), (value) => {
        if (!value) return false;
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(value);
      })
      .required(t("mail is required")),
  
  })

  const checkOtpValidation = yup.object({
    email_id: yup
      .string()
      .email(t("enter a Valid mail address"))
      .test('email-validation', t("enter a Valid mail address"), (value) => {
        if (!value) return false;
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(value);
      })
      .required(t("mail is required")),
      otp:yup
      .string()
      .required(t("otp require"))
  })

  const formik = useFormik({
    initialValues,
    onSubmit: value => handleSubmitOtp(value),
    enableReinitialize: true,
    validationSchema: otpSent ? checkOtpValidation : schemaValidation 
  })

  const handleSendOtp = () => {
    axiosInstance.post("send_otp", { "email_id": formik.values.email_id })
      .then(res => {
        if (res.data.status) {
          setOTPSent(true)
          ADMIN.toast.success(res.data.response.message)
        } else {
          ADMIN.toast.warn(res.data.response.message)
        }
      })
      .catch(e => { console.log(e.message); ADMIN.toast.error(e.message) })
  }

  const handleVerifyOtp = () => {
    axiosInstance.post("login_verify_otp", { "email_id": formik.values.email_id, "otp":formik.values.otp })
      .then(res => {
        if (res.data.status) { 
          navigate(laung == 'en' ? `/${laung}/newpassword` : "/newpassword", { state:{token:res.data.response.token}})
        } else {
          ADMIN.toast.warn(res.data.response.message)
        }
      })
      .catch(e => { console.log(e.message); ADMIN.toast.error(e.message) })
  }
  const handleSubmitOtp = () => {
   {otpSent ? handleVerifyOtp() : handleSendOtp()}
  }
  
  return (
    <>
      <div  className={laung == "ar" ? styles.ar_login_banner : styles.login_banner} >
        <img src={loginbanner} className={styles.banner_img} alt="" />
        <div className={styles.login_header}
          onClick={() => navigate(laung == 'en' ? `/${laung}` : "/")}
        >
          <img src={VenderlaneLogo} alt="" className={styles.venderlane_logo} />
        </div>
      </div>
      <div className={laung == "ar" ? styles.ar_container : styles.container} >
        <div className={styles.login_form_container}>
          <div className={styles.body} >
            <div className={styles.login_form}>
              <h1>{t('Forgot password?')}</h1>
              <p>{t('No worriest! Just enter your email and we’ll send you a reset password link')}</p>
              <form onSubmit={formik.handleSubmit}>
                <div className={styles.form_group}>
                  <input
                    name="email_id"
                    id="email_id"
                    type="email"
                    disabled={otpSent}
                    className={styles.input}
                    value={formik.values.email_id}
                    onChange={formik.handleChange}
                    placeholder={t('Enter Your Email')}
                  />
                  {(formik.errors.email_id && formik.touched.email_id) &&
                    <span className={styles.error_message}
                      style={{
                        fontFamily: laung == "ar" ? "Cairo-Regular"
                          : "inter-Regular"
                      }} >
                      {formik.errors.email_id}</span>}
                </div>
                {otpSent &&
                  <div className={styles.otp_sec}>
                    <OtpInput
                      containerStyle={{
                        direction: "ltr",
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      name="otp"
                      id="otp"
                      required
                      value={formik.values.otp}
                      onChange={formik.handleChange("otp")}
                      numInputs={6}
                      inputType="number"
                      renderInput={(props) => <input {...props} />}
                    />
                     {(formik.errors.otp && formik.touched.otp) &&
                    <span className={styles.error_message}
                      style={{
                        fontFamily: laung == "ar" ? "Cairo-Regular"
                          : "inter-Regular"
                      }} >
                      {formik.errors.otp}</span>}
                  </div>
                  
                  }
                <button type='submit' className={styles.login_button}  style={{
                        fontFamily: laung == "ar" ? "Cairo-Regular"
                          : "inter-Regular"
                      }}>{otpSent ? t("verify otp"): t("send otp") }</button>
              </form>
              <p className={styles.protect_p}
                 style={{
                  fontFamily: laung == "ar" ? "Cairo-Regular"
                    : "inter-Regular"
                }}
              >{t("Just remember?")}  <span onClick={() => navigate(-1)}> {t("Sign in")}</span></p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ForgotPassword