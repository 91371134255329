import React from 'react';
import styles from "./CustomHeader.module.scss";
const laung = localStorage.getItem("laung");


export const CustomeHeaderH1 = (props) => {
    const laung = localStorage.getItem("laung");
    return (
        <h1
            style={props.style}
            className={laung == "ar" ? styles.ar_heading : styles.heading}
            onClick={props.onClick}
        >
            {props.children}
        </h1>
    )
}


export const CustomeHeaderH3 = (props) => {
    return(
      <h3
        style={props.style}
        onClick={props.onClick}
        className={laung == "ar" ? styles.ar_subheading : styles.subheading}
      >
        {props.children}
      </h3>
    )
  };
  
  export const CustomeHeaderP = (props) => {
    return (
      <p
        style={props.style}
        onClick={props.onClick}
        className={laung == "ar" ? styles.ar_para_text : styles.para_text}
      >
        {props.children}
      </p>
    )
  }

