import React, { Fragment, useEffect } from 'react';
// import ClearanceSale from './clearancesale/ClearanceSale';
// import Departments from './Departments/Departments';
import CarouselBanner from './LandingHeader/Carousel/Carousel';
// import Categories from './LandingHeader/Categories/Categories';
// import ComboOffer from './LandingHeader/ComboOffer/ComboOffer';
import HomeEssentials from './LandingHeader/Home/HomeEssentials';
import styles from "./LandingPage.module.scss";
// import TopProducts from './TopProducts/TopProducts';
import { Outlet, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { fetchHomepageDetails } from '../../redux/slice/departmentSlice';
import { useTranslation } from 'react-i18next';
import HomepageLoader from '../Home/HomepageLoader';

const HomePage = ({ loading, setIsLoading }) => {
  const laung = localStorage.getItem("laung")
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const params = useParams();
  const { categoryname } = params;
  const DepartMentID = localStorage.getItem("categoryname")
  const details = useSelector((state) => state?.homePageDetails?.data?.response);

  const removingFilters = () => {
    let keysToRemove = ["filteredCities", "filteredSubCategories", "filteredBrands", "filterSortingID", "filterCustomerReview", "searchTerm", "minimum_price", "maximum_price"];
    keysToRemove.forEach(k => localStorage.removeItem(k));
  };
  useEffect(() => {
    dispatch(fetchHomepageDetails(categoryname))
    setTimeout(() => {
      setIsLoading(false)
    }, 500)
    removingFilters()
  }, [categoryname])

  return (
    <Fragment>
      {Array.isArray(details) && (
        <>
          {
            loading ? <HomepageLoader /> :
              <div className={styles.container}>
                {(details && details.banner?.length != 0) ? <div className={styles.banner} >
                  <CarouselBanner bannerData={details.banner} />
                </div> : null}
                <div className={styles.section_one} >
                  {/* {(details && details?.section2?.length != 0) && <Offers sectionTwo={details?.section2} />} */}
                  {/* {(details && details.category.length != 0) &&
                    <>
                      <p className={laung == "ar" ? styles.shop_by_cat_arabic : styles.shop_by_cat} style={{ fontFamily: laung == "ar" ? "Cairo-Semibold" : "Poppins-Medium" }} >{t("shop by category")}</p>
                      <div className={styles.categories} >
                        <Categories categoryData={details.category} laung={laung} />
                      </div>
                    </>
                  } */}
                  {/* {(details && details.top_product.length != 0) && <div className={styles.top_products} >
                    <TopProducts topProducts={details.top_product} />
                  </div>} */}
                </div>
                {(details && details?.section5.length != 0) && <div className={styles.home_essentials} >
                  <HomeEssentials sectionFive={details?.section5} laung={laung} />
                </div>}
                {/* {(details && details.combos.length != 0) && <div className={styles.section_three} >
                  <ComboOffer
                    offerProducts={details?.offer_products}
                    scetionSeven={details?.section7}
                    combos={details.combos}
                    sectionNine={details?.section9}
                    comboOfferId={details.combos_offer_id}
                  />
                </div>} */}
                {/* {(details && details.clearance_sale.length != 0) && <div className={styles.section_four} >
                  <ClearanceSale
                    sectionTen={details?.section10}
                    clearProducts={details.clearance_sale}
                    clearanceOfferId={details.clearance_offer_id}
                  />
                </div>} */}
                {/* {details && details.banner.length == 0 &&
                  details.category.length == 0 &&
                  details.clearance_sale.length == 0 &&
                  details.combos.length == 0 &&
                  details.offer_products.length == 0 &&
                  details.section2.length == 0 &&
                  details.section5.length == 0 &&
                  details.section7.length == 0 &&
                  details.section9.length == 0 &&
                  details.section10.length == 0 &&
                  details.top_product.length == 0 &&
                  <NodataTwo laung={laung} />} */}
                {/* <div className={styles.departments} >
                  <Departments laung={laung} />
                </div> */}
                <Outlet />
              </div>
          }
        </>
      )}
    </Fragment>
  )
}

export default HomePage