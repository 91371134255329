import React, { useState, useEffect } from 'react';
import styles from "./SellersProductList.module.scss";
import Filter from "../ViewAll/assets/filter.svg";
import Sort from "../ViewAll/assets/sort.svg";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SellerCard from './SellerCard';
import { VenderList } from '../../redux/slice/venderListSlice';
import Nodata from '../../constants/Nodata';
import { Outlet } from 'react-router-dom';
import { cityList } from '../../redux/slice/getCities';
import SellerCategoriesSwipper from './SellerCategoriesSwipper';
import { sellerCategories } from '../../services/sellerregistration.service';
import { ADMIN } from '../../services/admin.service';

const SellersProductList = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const SPID = localStorage.getItem("spCategory");
  const laung = localStorage.getItem('laung');

  const Sorting = [
    {
      "id": "1",
      "title": "A-Z"
    },
    {
      "id": "2",
      "title": "Z-A"
    },
  ];

  const [showFilters, setShowFilters] = useState(false);
  const [showsort, setShowsort] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(SPID ? SPID : "");
  const [sortingId, SetSortingId] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [categories, setCategories] = useState([]);

  const vendorList = useSelector((state) => state?.vendorList?.data?.response);
  const cityDetails = useSelector((state) => state?.cityList?.data?.response);

  const handleSelectFilter = (subcat) => {
    if (subcat.id != SPID) {
      localStorage.setItem("spCategory", subcat.id)
    }
    if (selectedFilter === subcat.id) {
      setSelectedFilter("")
      localStorage.removeItem("spCategory")
    } else {
      setSelectedFilter(subcat.id)
    }
  };

  useEffect(() => {
    dispatch(VenderList({ selectedFilter, sortingId, selectedCity }))
  }, [selectedFilter, sortingId, selectedCity])

  useEffect(() => {
    sellerCategories()
      .then(res => {
        if (res.data.status) {
          setCategories(res.data.response)
        }
      })
      .catch(e => ADMIN.toast.error(e.message))
  }, []);

  useEffect(() => {
    dispatch(cityList());
  }, [])

  const handleSelectSorting = (subcat) => {
    if (sortingId == subcat.id) {
      SetSortingId("")
    } else {
      SetSortingId(subcat.id)
    }
  };

  const handleSelectCity = (city) => {
    if (selectedCity == city.id) {
      setSelectedCity("")
    } else {
      setSelectedCity(city.id)
    }
  }

  return (
    <div className={styles.container} >
      <div className={styles.filter_listing} >
        <div className={styles.filter_listing_sec}>
          <h1 style={{fontFamily:laung == "ar" ? "Cairo-Semibold":"Poppins-SemiBold"}} >{t("Service Provider Category")}y</h1>
          <p style={{fontFamily:laung == "ar" ? "Cairo-Regular":"Poppins-Regular"}} >{t("Upgrade your work with best Service Provider")}</p>
        </div>
      </div>

      <div className={styles.seller_flex}>
        <div className={styles.seller_left}>
          <h2 style={{fontFamily:laung == "ar" ? "Cairo-Semibold":"Poppins-SemiBold"}} >{t("Category")}</h2>
          {
            categories && categories?.map((cat, indi) => {
              return (
                <div className={styles.category_left} key={indi} onClick={() => handleSelectFilter(cat)}>
                  <div className={styles.category} key={indi} >
                    <input 
                      type="checkbox"
                      checked={cat.id == selectedFilter}
                    />
                    <p style={{ fontFamily: laung == "ar" && "Cairo-Semibold" }} >{cat?.title}</p>
                  </div>
                </div>
              )
            })
          }
        </div>
        <div className={styles.seller_right}>
          <section className={styles.stores_count} >
            <section className={styles.filters} >
              <button onClick={() => setShowsort(!showsort)} style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "Poppins-Medium" }} ><img src={Sort} alt="" />{t("sort")}</button>
              <button onClick={() => setShowFilters(!showFilters)} style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "Poppins-Medium" }} ><img src={Filter} alt="" />{t("filters")}</button>
            </section>
          </section>
          {
            showsort &&
            <div className={styles.fliter_list} >
              <h5 style={{fontFamily:laung == "ar" ? "Cairo-Regular":"Poppins-Regular"}} >{t("sort")}</h5>
              <div className={styles.filter_types}  >
                {Sorting.map((subcat, indi) => <p
                  style={{
                    border: sortingId === subcat.id ? "2px solid #958559" : " 1px solid #D9CBA0",
                    background: sortingId === subcat.id ? " #958559" : " transparent",
                    color: sortingId === subcat.id ? " #fff" : " #121212",
                    fontFamily:laung == "ar" ? "Cairo-Regular":"Poppins-Regular"
                  }}
                  onClick={() => handleSelectSorting(subcat)} key={indi} >{subcat?.title}</p>)
                }
              </div>
            </div>
          }
          {(cityDetails && showFilters) &&
            <div className={styles.fliter_list} >
              <h5 style={{fontFamily:laung == "ar" ? "Cairo-Regular":"Poppins-Regular"}} >{t("Cities")}</h5>
              <div className={styles.filter_types}  >
                {cityDetails.map((city, indi) => <p
                  style={{
                    border: selectedCity === city.id ? "2px solid #958559" : " 1px solid #D9CBA0",
                    background: selectedCity === city.id ? " #958559" : " transparent",
                    color: selectedCity === city.id ? " #fff" : " #2A2821",
                    fontFamily:laung == "ar" ? "Cairo-Regular":"Poppins-Regular"

                  }}
                  onClick={() => handleSelectCity(city)} key={indi} >{city?.title}</p>)}
              </div>
            </div>}

          {
            vendorList?.length != 0 ?
              <section className={styles.seller_cards} >
                {
                  vendorList && vendorList.map((data, indi) => {
                    return (
                      <SellerCard key={indi} vendor={data} />
                    )
                  })
                }
              </section>
              : <Nodata laung={laung} />
          }
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default SellersProductList