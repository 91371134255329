import React, { useState, useEffect, useRef } from 'react';
import styles from "./Register.module.scss";
import UpArrow from "../../../assets/Images/svgs/up_arrow.svg";
import { Formik, Form } from 'formik';
import * as yup from "yup";
import { useNavigate } from 'react-router';
import { getDepartments } from '../../../services/LandingPage';
import { registerUser, sendOTP, pendingregisterUser } from '../../../services/Profile';
import { ADMIN } from '../../../services/admin.service';
import { useTranslation } from 'react-i18next';
import { AddToCart, AddToWishList } from '../../../services/Product.service';
import { menuCounts } from '../../../redux/slice/menuCountSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import Select from 'react-select';
import axiosInstance from '../../../services/api';
import Spinner from '../../../constants/spinner/Spinner';
import { generateToken } from '../../../utils/tokenGenerator';
import { fireStore } from '../../../firebase/config';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, } from "firebase/storage";
import ProductLoader from '../../../constants/ProductLoader';
import loginbanner from '../../../assets/Images/pngs/login_banner.png';
import VenderlaneLogo from '../../../assets/Images/svgs/venderlane_logo_1.svg';
import { getTimestampString } from '../../../constants/timestamp';
import Upload from "../../../assets/Images/svgs/Upload2.svg";

const phoneRegExp = /^([5-9]{1})([0-9]{9})$/
const saudiRegex = /^([5]{1})([0-9]{8})$/;

const Register = () => {

  const formikRef = useRef();
  const certiFicateRef = useRef(null)
  const location = useLocation();
  const userMobileInfo = location.state;
  const phoneNumberURL = location.state?.mobile_number;
  const laung = (localStorage.getItem('laung'));
  let storedToken = localStorage.getItem('uniqueToken');

  const navigate = useNavigate()
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [departments, setDepartMents] = useState("");
  const [sentOTP, setSentOTP] = useState(false);
  const [timer, setTimer] = useState(0);
  const [canResend, setCanResend] = useState(false);
  const [loading, setLoading] = useState(false);
  const [certificate, setCertificate] = useState(null);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [countryCode, setCountryCode] = useState("+966");

  const departmentDetails = useSelector((state) => state?.departMentDetails?.data?.response);
  const formatDate = (date) => {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  const today = formatDate(new Date());
  const regestrationSchema = yup.object({
    fullName: yup
      .string()
      .required(t("first name is required")),
    lastName: yup
      .string()
      .required(t("last name is required")),
    mobileNumber: yup
      .string()
      .min(9, t("minimum number"))
      .max(10, t('Maximum of 10 number you can use'))
      .matches(countryCode == "+966" ? saudiRegex : phoneRegExp, t("Please enter the valid mobile number"))
      .required(t("mobile number is required")),
    emailID: yup
      .string()
      .email(t("enter a Valid mail address"))
      .test('email-validation', t("enter a Valid mail address"), (value) => {
        if (!value) return false;
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(value);
      })
      .required(t("mail is required")),
    companyName: yup
      .string()
      .required(t("company name is required")),
    certificate: yup
      .mixed()
      .required(t("file is Required*")),
    crNumber: yup
      .string()
      .matches(/^(?!.*(\d)\1{3}).*$/, t('Please enter correct CR number'))
      .notOneOf([
        '0123456789',
        '123456789',
        "1234567891",
        "1234567890",
        "012345678",
        "12345678",
        "01234567",
        "1234567",
        "0123456",
        "123456",
        "012345",
        "12345",
        "01234",
        "1234",
        "0123",
        "123",
        "1111111111",
        "2222222222",
        "3333333333",
        "4444444444",
        "5555555555",
        "6666666666",
        "7777777777",
        "8888888888",
        "9999999999",
        "0000000000"], t("Please enter correct CR number"))
      .min(10, t("Minimum 10 digits Required")),

    department: yup
      .string()
      .required(t("department is required")),
    password: yup
      .string()
      .required(t("password is required"))
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, t("must contain")),
    confirmPassword: yup
      .string()
      .required(t("confirm passowrd is required"))
      .oneOf([yup.ref("password"), null], t("passwords are not matching")),
    dob: yup
      .date()
      .max(new Date(), t('Date of birth cannot be in the future')),
    otp: yup
      .number()
      .required(t("otp require"))
      .min(6, t("minimum otp")),
    business_type: yup
      .string()
      .required(t('This field is required')),
    fl_number: yup.string()
      .min(8, t("Minimum 8 digits Required"))
      // .max(15, t("max num_fl"))
      .matches(/^(?!.*(\d)\1{3}).*$/, t('Please enter correct FL number'))
      .notOneOf([
        '0123456789',
        '123456789',
        "1234567891",
        "1234567890",
        "012345678",
        "12345678",
        "01234567",
        "1234567",
        "0123456",
        "123456",
        "012345",
        "12345",
        "01234",
        "1234",
        "0123",
        "123",
        "1111111111",
        "2222222222",
        "3333333333",
        "4444444444",
        "5555555555",
        "6666666666",
        "7777777777",
        "8888888888",
        "9999999999",
        "0000000000"], t("Please enter correct FL number")),
  });

  const withoutOTPSchema = yup.object({
    fullName: yup
      .string()
      .required(t("first name is required")),
    lastName: yup
      .string()
      .required(t("last name is required")),
    mobileNumber: yup
      .string()
      .min(9, t("minimum number"))
      .max(10, t('Maximum of 10 number you can use'))
      .matches(countryCode == "+966" ? saudiRegex : phoneRegExp, t("Please enter the valid mobile number"))
      .required(t("mobile number is required")),
    emailID: yup
      .string()
      .email(t("enter a Valid mail address"))
      .required(t("mail is required")),
    companyName: yup
      .string()
      .required(t("company name is required")),
    certificate: yup
      .mixed()
      .required(t("file is Required*")),
    crNumber: yup
      .string()
      .matches(/^(?!.*(\d)\1{3}).*$/, t('Please enter correct CR number'))
      .notOneOf([
        '0123456789',
        '123456789',
        "1234567890",
        "1234567891",
        "012345678",
        "12345678",
        "01234567",
        "1234567",
        "0123456",
        "123456",
        "012345",
        "12345",
        "01234",
        "1234",
        "0123",
        "123",
        "1111111111",
        "2222222222",
        "3333333333",
        "4444444444",
        "5555555555",
        "6666666666",
        "7777777777",
        "8888888888",
        "9999999999",
        "0000000000"], t("Please enter correct CR number"))
      .min(10, t("Minimum 10 digits Required")),
    department: yup
      .string()
      .required(t("department is required")),
    password: yup
      .string()
      .required(t("password is required"))
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, t("must contain")),
    confirmPassword: yup
      .string()
      .required(t("confirm passowrd is required"))
      .oneOf([yup.ref("password"), null], t("passwords are not matching")),
    dob: yup
      .date(),
    business_type: yup
      .string()
      .required(t('This field is required')),
    fl_number: yup.string()
      .min(8, t("Minimum 8 digits Required"))
      .matches(/^(?!.*(\d)\1{3}).*$/, t('Please enter correct FL number'))
      .notOneOf([
        '0123456789',
        '123456789',
        "1234567891",
        "1234567890",
        "012345678",
        "12345678",
        "01234567",
        "1234567",
        "0123456",
        "123456",
        "012345",
        "12345",
        "01234",
        "1234",
        "0123",
        "123",
        "1111111111",
        "2222222222",
        "3333333333",
        "4444444444",
        "5555555555",
        "6666666666",
        "7777777777",
        "8888888888",
        "9999999999",
        "0000000000"], t("Please enter correct FL number")),
  });

  const handleGetDepartment = () => {
    getDepartments()
      .then(res => {
        if (res.data.status) {
          setDepartMents(res.data.response)
        }
      })
      .catch(e => console.error(e, "departments error"))
  }

  const handleTokenGenerator = () => {
    if (!storedToken) {
      storedToken = generateToken();
      localStorage.setItem('uniqueToken', storedToken);
    }
  }

  const handleGetPendingUsers = () => {
    axiosInstance.get(`pending_register.php?type=2&token=${storedToken}`)
      .then(res => {
        if (res.data.status) {
          res.data.response.fullName && formikRef.current.setFieldValue("fullName", res.data.response.fullName);
          res.data.response.lastName && formikRef.current.setFieldValue("lastName", res.data.response.lastName)
          res.data.response.emailID && formikRef.current.setFieldValue("emailID", res.data.response.emailID)
          res.data.response.companyName && formikRef.current.setFieldValue("companyName", res.data.response.companyName)
          res.data.response.crNumber && formikRef.current.setFieldValue("crNumber", res.data.response.crNumber)
          res.data.response.department && formikRef.current.setFieldValue("department", res.data.response.department)
          res.data.response.mobileNumber && formikRef.current.setFieldValue("mobileNumber", res.data.response.mobileNumber)
          res.data.response.password && formikRef.current.setFieldValue("password", res.data.response.password)
          res.data.response.confirmPassword && formikRef.current.setFieldValue("confirmPassword", res.data.response.confirmPassword)
          res.data.response.dob && formikRef.current.setFieldValue("dob", res.data.response.dob)
          res.data.response.business_type && formikRef.current.setFieldValue("business_type", res.data.response.business_type)
          res.data.response.fl_number && formikRef.current.setFieldValue("fl_number", res.data.response.fl_number);
          res.data.response.vat_number && formikRef.current.setFieldValue("vat_number", res.data.response.vat_number);
          res.data.response.country_code && setCountryCode(res.data.response.country_code);
        } else {
          console.log(res.data.response.message, "the false status")
        }
      })
      .catch(error => {
        console.log(error.message, "the pending user errors")
      })
  };

  useEffect(() => {
    handleTokenGenerator()
    handleGetPendingUsers();
    setCountryCode(userMobileInfo && userMobileInfo.country_code)
  }, []);

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1)
      }, 1000)
      return () => clearInterval(interval)
    } else {
      setCanResend(true)
    }
  }, [timer])

  const InitialValues = {
    fullName: userMobileInfo?.first_name ? userMobileInfo?.first_name : "",
    lastName: userMobileInfo?.last_name ? userMobileInfo?.last_name : "",
    emailID: userMobileInfo?.email_id ? userMobileInfo?.email_id : "",
    companyName: "",
    crNumber: "",
    department: "",
    gender: "",
    mobileNumber: userMobileInfo ? userMobileInfo.mobile_number : "",
    password: "",
    confirmPassword: "",
    dob: "",
    otp: "",
    business_type: "",
    fl_number: "",
    vat_number: "",
  };

  const withoutOTPInitialValues = {
    fullName: "",
    lastName: "",
    emailID: "",
    companyName: "",
    gender: "",
    crNumber: "",
    department: "",
    mobileNumber: userMobileInfo ? userMobileInfo.mobile_number : "",
    password: "",
    confirmPassword: "",
    dob: "",
    business_type: "",
    fl_number: "",
    vat_number: "",
  };

  const handleSendOtp = (formik) => {
    formik.setFieldValue("otp", "")
    setTimer(30)
    setCanResend(false);
    const checkData = {
      "country_code": countryCode,
      "mobile_number": formik.values.mobileNumber
    }
    sendOTP(checkData)
      .then(res => {
        if (res.data.status) {
          setSentOTP(true)
          ADMIN.toast.success(res.data.response.message);
        } else {
          ADMIN.toast.error(res.data.response.message);
        }
      })
      .catch(e => {
        ADMIN.toast.error(e);
      })
  };

  const handleRegisterUser = (values) => {
    const userInfo = {
      "first_name": values.fullName,
      "last_name": values.lastName,
      "country_code": countryCode,
      "mobile_number": userMobileInfo && userMobileInfo.mobile_number ? userMobileInfo.mobile_number : values.mobileNumber,
      "email_id": values.emailID,
      "password": values.password,
      "reg_from": "1",
      "gender": values.gender,
      "dob": values.dob,
      "cr_number": values.business_type == "1" ? values.crNumber : "",
      "company_name": values.companyName,
      "department_id": values.department,
      "otp": values.otp,
      "business_type": values.business_type,
      "fl_number": values.business_type == "2" ? values.fl_number : "",
      'token': storedToken,
      "cr_image": certificate,
      "vat_number": values.vat_number,
      "social_id": userMobileInfo?.social_id ? userMobileInfo.social_id : "",
      "social_token": userMobileInfo?.social_token ? userMobileInfo.social_token : "",
      "social_from": userMobileInfo?.social_from ? userMobileInfo.social_from : ""
    }
    certificate && setLoading(true)
    certificate && axiosInstance.post("register_verify_otp", userInfo)
      .then(res => {
        if (res.data.status) {
          setLoading(false)
          localStorage.removeItem("uniqueToken");
          localStorage.setItem("venlogtoken", res.data.response.token);
          localStorage.setItem("departmentID", res.data.response.department_id)
          const productID = (localStorage.getItem('productID'));
          const productQuantity = (localStorage.getItem('productQuantity'));
          const wishlistStat = (localStorage.getItem("stat"))
          if (productID && productQuantity) {
            const data = { "product_id": productID, "quantity": productQuantity }
            AddToCart(data)
              .then(res => {
                if (res.data.status) {
                  let keysToRemove = ["productID", "productQuantity"];
                  keysToRemove.forEach(k => localStorage.removeItem(k))
                  dispatch(menuCounts())
                } else { console.error(res.data.response.message) }
              })
              .catch(e => console.error(e, "add to kart error"))
          } else if (wishlistStat && productID) {
            AddToWishList(productID, wishlistStat)
              .then(res => {
                if (res.data.status) {
                  let keysToRemove = ["productID", "stat"];
                  keysToRemove.forEach(k => localStorage.removeItem(k))
                  dispatch(menuCounts())
                }
              })
              .catch(e => console.error(e, "add ro wishlist error"))
          }
          navigate(laung == 'en' ? `/${laung}` : "/")
          ADMIN.toast.success(res.data.response.message)
        } else {
          setLoading(false)
          ADMIN.toast.error(res.data.response.message)
        }
      })
      .catch(e => {
        setLoading(false)
        ADMIN.toast.error(e.message)
      }
      )
  };

  const RegisterWithoutOtp = (values) => {
    const withoutOtpuser = {
      "first_name": values.fullName,
      "last_name": values.lastName,
      "country_code": countryCode,
      "mobile_number": userMobileInfo && userMobileInfo.mobile_number ? userMobileInfo.mobile_number : values.mobileNumber,
      "email_id": values.emailID,
      "password": values.password,
      "reg_from": "1",
      "gender": values.gender,
      "dob": values.dob,
      "cr_number": values.business_type == "1" ? values.crNumber : "",
      "company_name": values.companyName,
      "department_id": values.department,
      "business_type": values.business_type,
      "fl_number": values.business_type == "2" ? values.fl_number : "",
      'token': storedToken,
      "cr_image": certificate,
      "vat_number": values.vat_number,
    }
    certificate && setLoading(true)
    certificate && registerUser(withoutOtpuser)
      .then(res => {
        if (res.data.status) {
          localStorage.removeItem("uniqueToken");
          setLoading(false)
          localStorage.setItem("venlogtoken", res.data.response.token);
          localStorage.setItem("departmentID", res.data.response.department_id)
          const productID = (localStorage.getItem('productID'));
          const productQuantity = (localStorage.getItem('productQuantity'));
          const wishlistStat = (localStorage.getItem("stat"))
          if (productID && productQuantity) {
            const data = { "product_id": productID, "quantity": productQuantity }
            AddToCart(data)
              .then(res => {
                if (res.data.status) {
                  let keysToRemove = ["productID", "productQuantity"];
                  keysToRemove.forEach(k => localStorage.removeItem(k))
                  dispatch(menuCounts())
                } else { console.error(res.data.response.message) }
              })
              .catch(e => console.error(e, "add to kart error"))
          } else if (wishlistStat && productID) {
            AddToWishList(productID, wishlistStat)
              .then(res => {
                if (res.data.status) {
                  let keysToRemove = ["productID", "stat"];
                  keysToRemove.forEach(k => localStorage.removeItem(k))
                  dispatch(menuCounts())
                }
              })
              .catch(e => console.error(e, "add ro wishlist error"))
          }
          navigate(laung == 'en' ? `/${laung}` : "/")
          ADMIN.toast.success(res.data.response.message)
        } else {
          setLoading(false)
          ADMIN.toast.error(res.data.response.message)
        }
      })
      .catch(e => { setLoading(false); ADMIN.toast.error(e) }
      )
  }

  const handleWithoutReg = (formik, name) => {
    formik.setFieldTouched(name)
    const data = {
      ...formik.values,
      type: "2",
      token: storedToken,
      "country_code": countryCode,
    }
    pendingregisterUser(data)
      .then((res) => {
        if (res.data.status) {
          // console.log(res.data.response.message, "success")
        } else {
          console.log(res.data.response.message, "failed")
        }
      })
      .catch(error => console.log(error.message, "the error message"))
  }

  const handlefirebaseUpload = (file) => {
    const storageref = ref(getStorage(fireStore), `/Propety_type/${file.name}/${getTimestampString()}`)
    const uploadTask = uploadBytesResumable(storageref, file);
    uploadTask.on(
      "state_changed", (snapshot) => {
        const percentage = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
      },
      (err) => {
        ADMIN.toast.error(err)
      },
      async () => {
        await getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setCertificate(url)
          return
        }).catch((err) => {
          ADMIN.toast.error(err)
        })
      }
    );
  };

  const handleUploadClick = () => {
    if (certiFicateRef.current) {
      certiFicateRef.current.click();
    }
  };

  return (
    <>
      <div className={laung == "ar" ? styles.ar_login_banner : styles.login_banner}>
        <img src={loginbanner} className={styles.banner_img} alt="" />
        <div className={styles.login_header}>
          <img src={VenderlaneLogo} alt={t("venderlane")} className={styles.venderlane_logo} onClick={() => navigate(laung == 'en' ? `/${laung}` : "/")} />
        </div>
      </div>
      <div className={laung == "ar" ? styles.ar_container : styles.container} >
        <div className={styles.login_form_container}>
          <div className={styles.body} >
            <h1 className={styles.login_text} style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-semibold" }} >{t("register with us")}</h1>
            <Formik
              innerRef={formikRef}
              initialValues={userMobileInfo && userMobileInfo.mobile_number ? withoutOTPInitialValues : InitialValues}
              validationSchema={userMobileInfo && userMobileInfo.mobile_number ? withoutOTPSchema : regestrationSchema}
              onSubmit={(values, errors, setErrors, isSubmitting) => {
                values.business_type == 1 && !values.crNumber && setErrors({ "crNumber": t("cr number is required") })
                values.business_type == 2 && !values.fl_number && setErrors({ "crNumber": t('This field is required') })
                !isSubmitting && document.getElementById('mobile_number').scrollIntoView();
                if ((values.business_type == 1 && values.crNumber && !errors.crNumber) || (values.business_type == 2 && values.fl_number && !errors.fl_number)) {
                  if (termsAgreed) {
                    userMobileInfo && userMobileInfo.mobile_number ? RegisterWithoutOtp(values) : handleRegisterUser(values)
                  } else {
                    ADMIN.toast.warn(t("Please accept Terms and Conditions!"))
                  }
                }
              }}
            >
              {
                (formik) => {
                  return (
                    <Form>
                      <div className={styles.inputs} >
                        <div className={styles.form_group} >
                          <label style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }} htmlFor="fullName">{t("first name")}</label>
                          <input
                            id="fullName"
                            name="fullName"
                            className={styles.input}
                            type="text"
                            placeholder={t("Please enter the first name")}
                            onChange={formik.handleChange("fullName")}
                            value={formik.values.fullName}
                            onBlur={() => handleWithoutReg(formik, "fullName")}
                            style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }}
                          />
                          {(formik.errors.fullName && formik.touched.fullName) && <span className={styles.error_message} style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }}>{formik.errors.fullName}</span>}
                        </div>

                        <div className={styles.form_group} id="mobile_number" >
                          <label htmlFor="fullName" style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }}>{t("last name")}</label>
                          <input
                            id="lastName"
                            name="lastName"
                            className={styles.input}
                            type="text"
                            placeholder={t("Please enter the last name")}
                            onChange={formik.handleChange("lastName")}
                            value={formik.values.lastName}
                            onBlur={() => handleWithoutReg(formik, "lastName")}
                            style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }}
                          />
                          {(formik.errors.lastName && formik.touched.lastName) && <span style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }} className={styles.error_message}>{formik.errors.lastName}</span>}
                        </div>

                        <div className={styles.form_group} >
                          <label style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }} htmlFor="emailID">{t("email")}</label>
                          <input
                            id="emailID"
                            name="emailID"
                            className={styles.input}
                            type="email"
                            disabled={userMobileInfo && userMobileInfo.email_id}
                            placeholder={t("Please enter the valid email")}
                            onChange={formik.handleChange("emailID")}
                            value={formik.values.emailID}
                            onBlur={() => handleWithoutReg(formik, "emailID")}
                            style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }}
                          />
                          {(formik.errors.emailID && formik.touched.emailID) && <span className={styles.error_message} style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }} >{formik.errors.emailID}</span>}
                        </div>

                        <div className={styles.form_group}  >
                          <label style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }} htmlFor="mobileNumber">{t("mobile number")}</label>

                          <div className={styles.single_input} >
                            <select
                              disabled={sentOTP ? true : false}
                              name="countrycode" id="countrycode" className={styles.select_option}
                              value={countryCode}
                              onChange={(e) => setCountryCode(e.target.value)}
                            >
                              <option value="+966">KSA</option>
                              <option value="+91">India</option>
                            </select>
                            <input
                              disabled={sentOTP ? true : false}
                              id="mobileNumber"
                              maxLength={10}
                              name="mobileNumber"
                              className={styles.input}
                              type="text"
                              placeholder={laung == "ar" ? t("********5") : t("5********")}
                              onChange={formik.handleChange("mobileNumber")}
                              value={formik.values.mobileNumber}
                              onBlur={() => handleWithoutReg(formik, "mobileNumber")}
                              style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }}
                            />
                            {
                              <button onClick={() => handleSendOtp(formik)} disabled={!canResend || formik.errors.mobileNumber || !formik.values.mobileNumber} className={styles.otp_btn} >
                                {canResend ? t("send otp") : `${t("Resend OTP in")} ${timer} ${t("Seconds")}`}
                              </button>}
                          </div>
                          {(formik.errors.mobileNumber && formik.touched.mobileNumber) && <span style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }} className={styles.error_message} >{formik.errors.mobileNumber}</span>}
                          {(formik.values.mobileNumber && formik.values.mobileNumber.length >= 9 && formik.errors.otp && !sentOTP) && <span className={styles.error_message} style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }} >{formik.errors.otp}</span>}
                        </div>

                        <div className={styles.form_group} >
                          <label htmlFor="dob" style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }}> {t("dob")}</label>
                          <input
                            id="dob"
                            name="dob"
                            className={styles.input}
                            type="date"
                            onChange={formik.handleChange("dob")}
                            value={formik.values.dob}
                            max={today}
                            onBlur={() => handleWithoutReg(formik, "dob")}
                          />
                          {(formik.errors.dob) && <span className={styles.error_message} style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }} >{formik.errors.dob}</span>}
                        </div>
                        {sentOTP && <div className={styles.form_group}  >
                          <label htmlFor="otp" style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }}> {t("One Time Password")}</label>
                          <div className={styles.otp_sec}>
                            <OtpInput
                              containerStyle={{
                                direction: "ltr",
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                              value={formik.values.otp}
                              onChange={formik.handleChange("otp")}
                              numInputs={6}
                              inputType="number"

                              renderInput={(props) => <input {...props} />}
                            />
                          </div>
                          {(formik.errors.otp && formik.touched.otp) && <span className={styles.error_message} style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }} >{formik.errors.otp}</span>}
                        </div>
                        }

                        <div className={styles.form_group} >
                          <label htmlFor="business_type" style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }}>{t("Business Type")}</label>
                          <select
                            className={styles.input}
                            id="Gender"
                            name="Gender"
                            placeholder={t('Select Gender')}
                            value={formik.values.gender}
                            onChange={(e) => formik.setFieldValue("gender", e.target.value)}
                            style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }}
                          >
                            <option value="" disabled >{t("Select Your Gender")}</option>
                            <option value={"1"} style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }}>{t("Male")}</option>
                            <option value={"2"} style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }}>{t("Female")}</option>
                          </select>
                          {(formik.errors.gender && formik.touched.gender) && <span className={styles.error_message} style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }}>{formik.errors.gender}</span>}
                        </div>
                      </div>
                      <div className={styles.or}>
                        <p style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }}>{t("Business Information")}</p>
                      </div>
                      <div className={styles.inputs}>

                        <div className={styles.form_group} >
                          <label htmlFor="companyName" style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }}>{t("company name")}</label>
                          <input
                            id="companyName"
                            name="companyName"
                            className={styles.input}
                            type="text"
                            placeholder={t("company name")}
                            onChange={formik.handleChange("companyName")}
                            value={formik.values.companyName}
                            onBlur={() => handleWithoutReg(formik, "companyName")}
                            style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }}
                          />
                          {(formik.errors.companyName && formik.touched.companyName) && <span className={styles.error_message} style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }}>{formik.errors.companyName}</span>}
                        </div>
                        <div className={styles.form_group} >
                          <label htmlFor="business_type" style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }}>{t("Business Type")}</label>
                          <select
                            className={styles.input}
                            id="business_type"
                            name="business_type"
                            placeholder={t("Business Type")}
                            value={formik.values.business_type}
                            onChange={formik.handleChange("business_type")}
                            onBlur={() => handleWithoutReg(formik, "business_type")}
                            style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }}
                          >
                            <option value="" disabled >{t("Business Type")}</option>
                            <option value={"1"} style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }}>{t("Commercial entity")}</option>
                            <option value={"2"} style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }}>{t("Freelance")}</option>
                          </select>
                          {(formik.errors.business_type && formik.touched.business_type) && <span className={styles.error_message} style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }}>{formik.errors.business_type}</span>}
                        </div>
                        <div className={styles.form_group} >
                          <label htmlFor="vat_number" style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }}>{t("VAT Number")}</label>
                          <input
                            id="vat_number"
                            name="vat_number"
                            className={styles.input}
                            type="number"
                            placeholder={t("VAT Number")}
                            onChange={formik.handleChange("vat_number")}
                            value={formik.values.vat_number}
                            onBlur={() => handleWithoutReg(formik, "vat_number")}
                            style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }}
                          />
                        </div>

                        {formik.values.business_type === "1" &&
                          <div className={styles.form_group} >
                            <label htmlFor="crNumber" style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }}>{t("cr number")}</label>
                            <input
                              // maxLength={15}
                              minLength={10}
                              id="crNumber"
                              required
                              name="crNumber"
                              className={styles.input}
                              type="number"
                              placeholder={t("cr number")}
                              onChange={formik.handleChange("crNumber")}
                              value={formik.values.crNumber}
                              onBlur={() => handleWithoutReg(formik, "crNumber")}
                              style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }}
                            />
                            {(formik.errors.crNumber) && <span className={styles.error_message} style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }}>{formik.errors.crNumber}</span>}
                          </div>}

                        {formik.values.business_type === "2" &&
                          <div className={styles.form_group} >
                            <label htmlFor="fl_number" style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }}>{t("freelance license number")}</label>
                            <input
                              // maxLength={15}
                              minLength={8}
                              id="fl_number"
                              name="fl_number"
                              className={styles.input}
                              type="number"
                              required
                              placeholder={t("freelance license number")}
                              onChange={formik.handleChange("fl_number")}
                              value={formik.values.fl_number}
                              onBlur={() => handleWithoutReg(formik, "fl_number")}
                              style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }}
                            />
                            {(formik.errors.fl_number) && <span className={styles.error_message} style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }}>{formik.errors.fl_number}</span>}
                          </div>}

                        {
                          formik.values.business_type &&
                          <div className={styles.form_group} >
                            <label style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }}>{formik.values.business_type === "2" ? t("Free-Lance Certificate") : t("cr certificate")}</label>
                            <input
                              ref={certiFicateRef}
                              id="certificate"
                              name="certificate"
                              className={styles.input}
                              style={{ alignItems: "center", lineHeight: "100%", display: "none" }}
                              // required
                              type="file"
                              accept="image/*,application/pdf"
                              onChange={(e) => (handlefirebaseUpload(e.target.files[0]), formik.setFieldValue('certificate', e.target.files[0]))}
                            />
                            {!formik.values.certificate ? <p className={styles.upload_certificate} onClick={handleUploadClick} style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }} >{t("UPLOAD YOUR CR / FREELANCE CERTIFICATE")} <img src={Upload} alt="upload" /> </p>
                              : <p onClick={handleUploadClick} className={styles.upload_certificate} >{formik.values.certificate.name} <img src={Upload} alt="upload" /></p>}
                            {(formik.errors.certificate && formik.touched.certificate) && <span className={styles.error_message} style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }} >{formik.errors.certificate}</span>}
                          </div>
                        }
                        <div className={styles.form_group} >
                          <label htmlFor="department" style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }}>{t("department")}</label>
                          {departmentDetails &&
                            <Select
                              isMulti
                              getOptionLabel={option => option.title}
                              getOptionValue={option => `${option.id}`}
                              name="colors"
                              onChange={values => {
                                const selectedIds = values.map(option => option.id);
                                formik.setFieldValue("department", selectedIds.join(","));
                              }}
                              options={departmentDetails}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              placeholder={t("department")}
                              onBlur={() => handleWithoutReg(formik, "department")}
                              value={departmentDetails.filter((dept) => formik.values.department && formik.values.department.includes(dept.id))}
                            />
                          }
                          {(formik.errors.department && formik.touched.department) && <span className={styles.error_message} style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }}>{formik.errors.department}</span>}
                        </div>

                        <div className={styles.form_group} >
                          <label htmlFor="Password" style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }} >{t("password")}</label>
                          <input
                            id="password"
                            name="password"
                            className={styles.input}
                            type="password"
                            placeholder={t("Please enter the password")}
                            onChange={formik.handleChange("password")}
                            value={formik.values.password}
                            onBlur={() => handleWithoutReg(formik, "password")}
                            style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }}
                          />
                          {(formik.errors.password && formik.touched.password) && <span className={styles.error_message} >{formik.errors.password}</span>}
                        </div>

                        <div className={styles.form_group} >
                          <label htmlFor="confirmPassword" style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }}>{t("confirm password")}</label>
                          <input
                            id="confirmPassword"
                            name="confirmPassword"
                            className={styles.input}
                            type="password"
                            placeholder={t("Please enter the confirm password")}
                            onChange={formik.handleChange("confirmPassword")}
                            value={formik.values.confirmPassword}
                            onBlur={() => handleWithoutReg(formik, "confirmPassword")}
                            style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }}
                          />
                          {(formik.errors.confirmPassword && formik.touched.confirmPassword) && <span className={styles.error_message} style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }} >{formik.errors.confirmPassword}</span>}
                        </div>
                      </div>
                      <p className={styles.protect_p} style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular" }}>
                        <input
                          type="checkbox"
                          checked={termsAgreed}
                          onChange={(e) => setTermsAgreed(e.target.checked)}
                        />
                        {t('By clicking Create account, I agree that I have read and accepted the')} <span onClick={() => window.open(laung == 'en' ? `/${laung}/terms_conditions/1` : "/terms_conditions/1")} >{t("Agreement and Conditions")}</span>.
                      </p>
                      <button
                        disabled={loading}
                        style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-medium" }} 
                        className={styles.login_button}>{loading ? <Spinner /> : t("submit")}</button>

                    </Form>
                  )
                }
              }
            </Formik>
            {loading && <ProductLoader />}
            <p style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "inter-Regular", textAlign:"center", padding:"12px", cursor:"pointer" }}>
            {t("Already have an account?")}<span style={{ fontFamily: laung == "ar" ? "Cairo-Semibold" : "inter-semibold" }} onClick={() => navigate(laung == 'en' ? `/${laung}/login` : "/login")} > {t('Signing')}  </span>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Register