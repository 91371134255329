import React from 'react';
import styles from "./CustomButton.module.scss";
import cart from '../../assets/Images/svgs/white_cart.svg'

const CustomButton = (props) => {
    const laung = localStorage.getItem("laung")
    return (
        <button onClick={() => props.onClick()}
            disabled={props.disabled}
            className={styles.container}
            style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "Poppins-Medium" }}
        // style = { props.cartAvailablity == 0 ? { backgroundColor: "red", border:"1px solid red" } : { backgroundColor: "none", boder:"none" } } 
        >
            {props.title} <img src={cart} alt="" />
        </button>
    )
}

export default CustomButton;