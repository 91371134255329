import React, { useEffect, useRef, useState } from 'react';
import styles from "./LandingHeaderThree.module.scss";
import VenderlaneLogo from "../../../assets/Images/svgs/venderlane_logo_1.svg";
import langimg from "../../../assets/Images/svgs/language.svg";
import search from "../../../assets/Images/svgs/search_icon.svg";
import { ADMIN } from '../../../services/admin.service';
import downicon from "../../../assets/Images/svgs/down_icon.svg";
import shopcart from "../../../assets/Images/svgs/shop_cart.svg";
import menu_icon from "../../../assets/Images/pngs/even.png";
import Menu from "../../../assets/Images/svgs/menu_icon.svg"
import { useTranslation } from 'react-i18next';
import { getProductList } from '../../../services/Product.service';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '../../../assets/Images/svgs/CloseIcon.svg';
import Saudiflag from "../../../assets/Images/pngs/saudi-arabia.png";
import { menuCounts } from '../../../redux/slice/menuCountSlice';
import ReactModal from "react-modal";
import Popuppic from "../../../assets/Images/pngs/sidebanner.jpg";
import Cart from "../../../assets/Images/svgs/cart.svg";
import homesmile from "../../../assets/Images/svgs/home_smile.svg";
import Package from "../../../assets/Images/svgs/package.svg";
import usersquare from "../../../assets/Images/svgs/user_square.svg";


const customStyles = {
  content: {
    top: '10%',
    left: '50%',
    right: '50%',
    bottom: 'auto',
    borderRadius: "20px",
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const LandingHeaderThree = ({ setIndutry }) => {
  const departmentDetails = useSelector((state) => state?.departMentDetails?.data?.response);
  const Counts = useSelector((state) => state.menuCount.data?.response)

  const inputRef = useRef(null);
  const resultsRef = useRef(null);

  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation()
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const laung = (localStorage.getItem('laung'));
  const [language, setLanguage] = useState(laung ?? "ar");
  const languages = [
    { name: "العربية", code: "ar", dir: "rtl" },
    { name: "English", code: "en", dir: "lrt" },
  ];
  const categoryname = localStorage.getItem("categoryname");
  const searchingTerm = localStorage.getItem("searchTerm")
  const departmentID = localStorage.getItem("departmentID")

  const [active, setActive] = useState(false);
  const [searchresult, setSearchResult] = useState(false);
  const [result, setResult] = useState([]);
  const [searchTerm, setSearchTerm] = useState(searchingTerm);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpen1, setIsDropdownOpen1] = useState(false);
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
  const [scrollbutton, setScrollbutton] = useState(false);
  const [popupBanner, setPopupBanner] = useState(false)

  const [selectedCategory, setSelectedCategory] = useState(departmentID);


  const handleClickOutside = (event) => {
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target) &&
      resultsRef.current &&
      !resultsRef.current.contains(event.target)
    ) {
      setSearchResult(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    !laung && localStorage.setItem("laung", "ar")
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrollbutton(true);
      } else {
        setScrollbutton(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (location.pathname == "/en" || location.pathname == "/") {
      setSearchTerm("")
      localStorage.removeItem("searchTerm")
    }
    dispatch(menuCounts())
  }, [location.pathname])

  const venlogtoken = localStorage.getItem("venlogtoken");


  useEffect(() => {
    window.scrollTo(0, 0);
    { language == "ar" ? document.body.classList.add("arabic") : document.body.classList.remove("arabic") }
    i18n.changeLanguage(currentLangObj.code);
    document.body.dir = currentLangObj.dir;
  }, [language])


  useEffect(() => {
    setActive(false)
    if (location.pathname == '/' || location.pathname == "/en") {
      removingFilters()
    }
  }, [location.pathname])


  const currentLangObj = languages.find((lang) => lang.code == language);

  const updatedURL = (url, lang) => {
    if (lang == "ar" && url.includes("/en/")) {
      return url.replace("/en/", "/");
    } else if (lang == "ar" && url.includes("/en")) {
      return url.replace("/en", "/");
    } else {
      return lang == "en" && url.replace("/", "/en/");
    }
  }

  const handleChangeLocale = (e) => {

    const lang = e.target.value;
    localStorage.setItem("laung", lang);
    setLanguage(lang);
    i18n.changeLanguage(lang);
    const modifiedUrl = updatedURL(location.pathname, lang);
    window.location.replace(modifiedUrl);
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
    setIsDropdownOpen1(false);
  };

  const handleDropdownToggle1 = () => {
    setIsDropdownOpen1(!isDropdownOpen);
    setIsDropdownOpen(false);
  };

  const handleNavigateToTo = (type) => {
    switch (type) {
      case 0:
        navigate(laung == 'en' ? `/${laung}/login` : "/login")
        break;
      case 1:
        navigate(laung == "en" ? `/${laung}/account` : "/account")
        break;
      case 2:
        navigate(laung == "en" ? `/${laung}/account/my_profile` : "/account/my_profile")
        break;
      case 3:
        navigate(laung == "en" ? `/${laung}/wish_list` : "/wish_list")
        break;
      case 4:
        navigate(laung == "en" ? `/${laung}/my_cart` : "/my_cart")
        break;
      case 5:
        navigate(laung == "en" ? `/${laung}/vendor_register` : "/vendor_register")
        break;
      case 6:
        navigate(laung == "en" ? `/${laung}/seller_register` : "/seller_register")
        break;
      case 7:
        navigate(laung == "en" ? `/${laung}/registration` : "/registration")
        break;
      default:
        break;
    }
  }

  const handleNavigate = (product) => {
    setSearchResult(false)
    const arbProductURL = `/${product.url == "" ? "p" : encodeURIComponent(product.url)}/p/${product.product_id}`
    const enProductURL = `/${laung}/${product.url == "" ? "p" : encodeURIComponent(product.url)}/p/${product.product_id}`
    navigate(laung == "en" ? enProductURL : arbProductURL, {
      state: {
        search: 1
      }
    })
  };

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const performSearch = (searchTerm) => {
    if (searchTerm.length >= 2) {
      getProductList({
        "search": searchTerm
      })
        .then(res => {
          setSearchResult(true)
          setResult(res.data.response.product_list)

        })
        .catch(e => ADMIN.toast.error(e))
    } else {
      setSearchResult(false)
    }
  };

  const debouncedSearch = debounce(performSearch, 500);


  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchTerm(() => value);
    debouncedSearch(value);
  };

  const handleSearchButton = (e) => {
    e.preventDefault()
    if (searchTerm.length > 2) {
      removingFilters()
      setSearchResult(false)
      localStorage.setItem("searchTerm", searchTerm)
      // if (params.producturl || location.pathname.includes("sellers_list")) {
      //   navigate(`/${categoryname}/all_products/p/searchedresult/${0}`, { state: { subID: "" } })
      //   setSearchTerm("");
      // } else {
      if (laung == "en") {
        window.open(`/${laung}/all_products/p/searchedresult/${''}`, "_blank")
      } else {
        window.open(`/all_products/p/searchedresult/${''}`, "_blank")
      }
      localStorage.setItem("fromsearch", true);
    }
    // }
  };

  const handleGethistory = () => {
    getProductList({
      "search": "search_history"
    })
      .then(res => {
        if (res.data.response.product_list.length != 0) {
          setSearchResult(true)
          setResult(res.data.response.product_list)
        }
      })
      .catch(e => ADMIN.toast.error(e))
  }

  const removingFilters = () => {
    let keysToRemove = ["filteredCities", "filteredSubCategories", "filteredBrands", "filterSortingID", "filterCustomerReview", "minimum_price", "maximum_price", "searchTerm", "browse_vendor"];
    keysToRemove.forEach(k => localStorage.removeItem(k));
  };

  const handleBlur = () => {
    setTimeout(() => {
      setSearchResult(false);
    }, 500);
  };

  const handleDepartments = (dept) => {
    setIndutry(dept.url)
    if (dept.id == selectedCategory) {
      return null;
    } else {
      setSelectedCategory(dept.id)
      window.open(laung == 'en' ? `/${laung}` : "/")
      localStorage.setItem("categoryname", dept.url)
      localStorage.setItem("departmentID", dept.id)
      localStorage.setItem("department_title", dept.title)
    }
  };

  return (
    <div className={scrollbutton == false ? styles.container : styles.sticky_header} id="the_container" >
      <div className={styles.top_small_banner} >
        <p className={styles.made_in_saudi} style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "Poppins-Medium" }}>
          {t("Venderlane saudi made platform")} &nbsp;  <img src={Saudiflag} alt="Saudi" style={{ height: "20px", width: "20px" }} />
        </p>
        <p
          className={styles.banner_popup} style={{ fontFamily: laung == "ar" ? "Cairo-Bold" : "Avenir_Heavy" }}
          onClick={() => setPopupBanner(true)}
        >{t("How to Buy?")}</p>
      </div>

      <div className={laung == "ar" ? styles.ar_header_container : styles.header_container} >

        <div className={styles.header_left} >
          <a onClick={() => navigate(laung == 'en' ? `/${laung}` : "/")} >
            <img src={VenderlaneLogo} className={styles.venderlane_logo} alt='Venderlane' />
          </a>
          <div className={`${styles.header_right_right} ${active ? 'header1' : ''}`}>
            <img className={styles.close_btn} onClick={() => setActive(false)} src={CloseIcon} alt='close' />
            <div className={styles.dropdown}
              onClick={() => navigate(laung == 'en' ? `/${laung}` : "/")}
            >
              <button className={`${styles.dropdown_toggle}`}>
                <span style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "Lexend-SemiBold" }}>{t("Home")}</span>
              </button>
            </div>
            <div className={styles.dropdown}
              onClick={() => window.open(laung == "en" ? `/${laung}/about_us` : "/about_us")}
            >
              <button className={styles.dropdown_toggle} >
                <span style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "Lexend-SemiBold" }}>{t("about us")}</span>
              </button>
            </div>
            <div className={styles.mobile_login_right}  >
              {!venlogtoken &&
                <div>
                  <button className={styles.dropdown_toggle}
                    onClick={() => handleNavigateToTo(0)}>
                      <span style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "Lexend-SemiBold" }}>{t("Signing")}</span>
                      </button>
                </div>
              }
            </div>
            <div className={styles.dropdown} onClick={handleDropdownToggle}
              onMouseEnter={() => setIsDropdownOpen(true)}
              onMouseLeave={() => setIsDropdownOpen(false)}>
              <button className={`${styles.dropdown_toggle} ${isDropdownOpen ? styles.open : ''}`}>
                <span style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "Lexend-SemiBold" }} >{t("department")}</span><img src={downicon} alt='' />
              </button>
              <div className={`${styles.dropdown_menu} ${isDropdownOpen ? styles.open : ''}`}>
                {
                  Array.isArray(departmentDetails) && departmentDetails.map((department, deptindi) => {
                    return (
                      <a key={deptindi} onClick={() => handleDepartments(department)}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: "15px",
                          fontFamily: laung == "ar" ? "Cairo-Regular" : "Lexend-SemiBold",
                          color: "#545454",
                          fontSize: "16px",
                          lineHeight: "20px",
                          paddingTop: "10px",
                          cursor: "pointer",
                          textDecoration: categoryname === department.url ? "underline" : "none"
                        }} >
                        <img src={department.image} alt={department.alt_text} style={{ height: "20px", width: "20px", borderRadius: "50%" }} />
                        <span>{department.title}</span>
                      </a>
                    )
                  })
                }
              </div>
            </div>
            {!venlogtoken && <div className={styles.mobile_login_right}
              onClick={handleDropdownToggle1}
              onMouseEnter={handleDropdownToggle1}
              onMouseLeave={() => setIsDropdownOpen1(false)}
            >
              <button className={`${styles.dropdown_toggle} ${isDropdownOpen1 ? styles.open : ''}`}>
                <span style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "Poppins-SemiBold" }}>{t("register with us")} </span><img src={downicon} alt='' />
              </button>
              <div className={`${styles.dropdown_menu} ${isDropdownOpen1 ? styles.open : ''}`}>
                <a style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "Poppins-Medium" }} className={styles.dropdown_item} onClick={() => handleNavigateToTo(7)} ><img src={usersquare} alt={t("Become a User")} /><span>{t("Become a User")}</span></a>
                <a style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "Poppins-Medium" }} className={styles.dropdown_item} onClick={() => handleNavigateToTo(5)}><img src={Package} alt={t("Become a Supplier")} /><span>{t("Become a Supplier")}</span></a>
                <a style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "Poppins-Medium" }} className={styles.dropdown_item} onClick={() => handleNavigateToTo(6)}><img src={Package} alt={t("Become a service provider")} /><span>{t("Become a service provider")}</span></a>
              </div>
            </div>}

            <div className={laung == "ar" ? styles.ar_header_first_mb : styles.header_first_mb} >
              <div className={styles.dropdown} >
                <div className={styles.drop_btn}>
                  <img src={langimg} alt="" />
                  <select onChange={handleChangeLocale} style={{ fontFamily: "Cairo-Regular" }} value={language}  >
                    {languages.map(({ name, code }) => (
                      <option key={code} value={code} >
                        {name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.lap_cart}>
          {venlogtoken && <div className={styles.dropdown} onClick={() => handleNavigateToTo(4)}
            onMouseEnter={() => setIsDropdownOpen2(true)}
            onMouseLeave={() => setIsDropdownOpen2(false)} >
            <button className={`${styles.dropdown_toggle} ${isDropdownOpen2 ? styles.open : ''}`}>
              {Counts?.cart_count > 0 && <p className={laung == "ar" ? styles.kart_number_arabic : styles.kart_number_en} ><span>{Counts?.cart_count}</span></p>}
              <img src={shopcart} alt={t("cart")} />
            </button>
          </div>}
          {venlogtoken && <img src={menu_icon} alt="Profile" onClick={() => handleNavigateToTo(1)} style={{ borderRadius: "50%", height: "25px", width: "25px" }} />}
          <button className={styles.open_btn} onClick={() => setActive(true)}>
            <img src={Menu} alt="" />
          </button>
        </div>
        <div className={`${styles.header_right} ${active ? 'header1' : ''}`}>
          <form onSubmit={handleSearchButton}
            style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "Poppins-Medium" }}
            className={styles.searching_items_right} >
            <button disabled={!searchTerm} type="submit" ><img src={search} alt="search" /></button>
            <input
              ref={inputRef}
              type="text"
              className={styles.search_input}
              value={searchTerm}
              placeholder={t("search")}
              onChange={(event) => handleInputChange(event)}
              onFocus={() => handleGethistory()}
              style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "Poppins-Medium", }}
            />
          </form>
          <div className={styles.user_sign} >
            {!venlogtoken ?
              <div style={{ display: "flex", justifyContent: "space-between", columnGap: "20px", alignItems: "center" }}>
                <div>
                  <p className={styles.sign_up}
                    onClick={() => handleNavigateToTo(0)}
                    style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "Poppins-Medium" }}>{t("Signing")}</p>
                </div>
                <div className={styles.dropdown} onClick={handleDropdownToggle1}
                  onMouseEnter={handleDropdownToggle1}
                  onMouseLeave={() => setIsDropdownOpen1(false)}>
                  <p className={`${styles.dropdown_toggle} ${styles.become_supplier} ${isDropdownOpen1 ? styles.open : ''}`}

                    style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "Poppins-Medium" }}>{t("register with us")}
                  </p>
                  <div className={`${styles.dropdown_menu} ${isDropdownOpen1 ? styles.open : ''}`}>
                    <a className={styles.dropdown_item} onClick={() => handleNavigateToTo(7)}>
                      <span>{t("Become a User")}</span></a>
                    <a className={styles.dropdown_item} onClick={() => handleNavigateToTo(5)} >
                      <span>{t("Become a Supplier")}</span></a>
                    <a className={styles.dropdown_item} onClick={() => handleNavigateToTo(6)}>
                      <span>{t("Become a service provider")}</span></a>
                  </div>
                </div>
              </div>
              :
              <div className={styles.user_logged_in} >
                <button>
                  {Counts?.cart_count > 0 && <p className={laung == "ar" ? styles.kart_number_arabic : styles.kart_number_en} ><span>{Counts?.cart_count}</span></p>}
                  <img src={Cart} onClick={() => handleNavigateToTo(4)} alt="cart" />
                </button>
                <img src={menu_icon} alt="Profile" onClick={() => handleNavigateToTo(1)} style={{ borderRadius: "50%" }} />
              </div>}
          </div>
        </div>
        <div className={laung == "ar" ? styles.ar_header_first : styles.header_first} >
          <div className={styles.dropdown} >
            <div className={styles.drop_btn}>
              <img src={langimg} alt="" />
              <select onChange={handleChangeLocale} style={{ fontFamily: "Cairo-Regular" }} value={language}  >
                {languages.map(({ name, code }) => (
                  <option key={code} value={code}  >
                    {name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <form className={styles.searching_items} onSubmit={handleSearchButton} >
          <input
            type="text"
            className={styles.search_input}
            value={searchTerm}
            placeholder={t("search")}
            onChange={(event) => handleInputChange(event)}
            onFocus={() => handleGethistory()}
            style={{ fontFamily: "Cairo-Regular", fontSize: "20px" }}
          />
          <button type='submit' ><img src={search} alt="search" /></button>
        </form>
      </div>
      {searchresult &&
        <div ref={resultsRef} className={laung == "ar" ? styles.search_result_arabic : styles.search_result} >
          {result.length != 0 ? result?.map((data, index) => {
            return (
              <div className={styles.result_product} onClick={() => handleNavigate(data)} key={index} >
                <img
                  src={data.image}
                  style={{ height: "30px", width: "30px", borderRadius: "10px" }}
                />
                <p style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "Poppins-Medium", width: "100%" }}>{data.title}</p>
              </div>
            )
          }) : <div className={styles.data_not_found} ><span style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "Poppins-Medium" }}>{t("results not found")}</span> </div>}
        </div>
      }
      <ReactModal
        isOpen={popupBanner}
        onRequestClose={() => setPopupBanner(state => !state)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className={styles.cancel_order} style={{ paddingTop: "20px" }} >
          <img className={styles.close_btn} onClick={() => setPopupBanner(state => !state)} src={CloseIcon} alt='close' />
          <img
            src={Popuppic} alt=""
            style={{ width: "100%", height: "100vh" }}
          />
        </div>
      </ReactModal>
    </div>
  )
}

export default LandingHeaderThree