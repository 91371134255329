import React, { useEffect, useState } from 'react';
import styles from "./OurStory.module.scss";
import { getAboutUsDetails } from '../../services/Homepage.service';
import BannerFlower from "../../assets/Images/pngs/banner_flower.png";
import AboutUsLoader from '../../constants/PreLoaders/AboutUsLoader';
import { H1 } from '../../constants/CustomText/VendorHeading';
import { useTranslation } from 'react-i18next';
import ourstorybanner from '../../assets/Images/pngs/ourstory.png';
import  descright from '../../assets/Images/pngs/desc_right.png';
import  descleft from '../../assets/Images/pngs/desc_left.png';



const OurStory = () => {
  const { t } = useTranslation();
  const laung = (localStorage.getItem('laung'));
  const [aboutDetails, setAboutDetails] = useState("");

  useEffect(() => {
    getAboutUsDetails("story")
      .then(res => {
        if (res.data.status) {
          setAboutDetails(res.data.response)
        }
      })
      .catch(err => console.error(err, "about us error"))
  }, []);

  return (
    <div className={styles.container} >
      <div className={styles.our_story}>
        <img src={ourstorybanner} alt="" />
      </div>
      {
        aboutDetails ?
          <>
            <div className={styles.body} >
              <h1 className={styles.ourstory_title}>{t("our story")}</h1>
              <div className={styles.desc_left}>
                <div className={styles.desc_section} >
                  <h1 className={styles.title} style={{ fontFamily: laung == "ar" ? "Cairo-Bold" : "Avenir_Heavy" }} >{aboutDetails[0].title}</h1>
                  <h6 className={styles.sub_tilte} style={{ fontFamily: laung == "ar" ? "Cairo-Semibold" : "Poppins-Medium" }} >{aboutDetails[0].sub_title}</h6>
                  <p className={styles.description} dangerouslySetInnerHTML={{ __html: aboutDetails[0].description }} style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "Poppins-Medium" }} />
                </div>
                <div className={styles.pic_section} >
                  <img className={styles.section_pic} src={aboutDetails[0].image} alt={aboutDetails[0].title} title={aboutDetails[0].title} />
                </div>
              </div>
              <div className={styles.desc_left} >
                <div className={styles.pic_section} >
                  <img className={styles.section_pic} src={aboutDetails[1].image} alt={aboutDetails[1].title} title={aboutDetails[1].title} />
                </div>
                <div className={styles.desc_section} >
                  <h1 className={styles.title} style={{ fontFamily: laung == "ar" ? "Cairo-Bold" : "Avenir_Heavy" }} >{aboutDetails[1].title}</h1>
                  <h6 className={styles.sub_tilte} style={{ fontFamily: laung == "ar" ? "Cairo-Semibold" : "Poppins-Medium" }} >{aboutDetails[1].sub_title}</h6>
                  <p className={styles.description} dangerouslySetInnerHTML={{ __html: aboutDetails[1].description }} style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "Poppins-Medium" }} />
                </div>
              </div>
            </div>
          </>
          :
          <AboutUsLoader />
      }

    </div>
  )
}

export default OurStory