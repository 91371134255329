import React, { useEffect, useState } from 'react'
import styles from "./Orders.module.scss";
import ChevLeft from "../../../assets/Images/svgs/chevrin_left.svg";
import ChevRight from "../../../assets/Images/svgs/chevRight.svg";
import Download from "../../../assets/Images/svgs/download.svg";
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { getOrderDetails, updateOrderStatus, vendorRatting, orderProductRatting } from '../../../services/Order.service';
import { ADMIN } from '../../../services/admin.service';
import axios from 'axios';
import { saveAs } from 'file-saver';
import Dialog from '@mui/material/Dialog';
import ChatModal from '../chat/ChatModal';
import { singelOrderChatDetails } from '../../../redux/slice/SingleOrderChat';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Rating from 'react-rating';
import { H3, P } from '../../../constants/CustomText/VendorHeading';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SpiningLoader from '../../../constants/SpiningLoader';
import Spinner from '../../../constants/spinner/Spinner';
import ReactModal from "react-modal";
import track from '../../../assets/Images/svgs/Tracking.svg';
import paymenttrack from '../../../assets/Images/svgs/payment_track.svg';
import tabarrow from '../../../assets/Images/svgs/tab_arrow.svg';
import clock from '../../../assets/Images/svgs/clock-icon.svg';
import calendar from '../../../assets/Images/svgs/calendar-icon.svg';
import transfericon from '../../../assets/Images/svgs/transfer_icon.svg';
import ActiveStar from "../../../assets/Images/svgs/active_star.svg";
import InActiveStar from "../../../assets/Images/svgs/inactive_star.svg";


const modalstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    textAlign: "center",
    alignItems: "center"
};

const customStyles = {
    content: {
        top: '30%',
        left: '50%',
        right: '50%',
        bottom: 'auto',
        borderRadius: "20px",
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};


const Orders = () => {
    const location = useLocation();
    const params = useParams();
    const dispatch = useDispatch()
    const { ovid } = params
    const navigate = useNavigate();
    const { t } = useTranslation()

    const laung = (localStorage.getItem('laung'));
    const [orderDetail, setOrderDetails] = useState([]);
    const [openModal, setModal] = useState(false);
    const [ibanModal, setIbanModal] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [acceptLoading, setAcceptLoading] = useState(false);
    const [vendorReason, setVendorReason] = useState("");
    const [orderCancel, setOrderCancel] = useState(false);
    const [rejectLoader, setRejectLoader] = useState(false);
    const [showTracking, setShowTracking] = useState(false);
    const [showPaymentDetails, setShowpaymentDetails] = useState(false);
    const [orderRating, setOrderOrderRating] = useState(0);
    const [comment, setComment] = useState("");

    useEffect(() => {
        handleGetOrderDetails()
    }, [ovid, location.pathname])

    const handleGetOrderDetails = () => {
        try {
            const encryptedId = atob(ovid);
            const originalId = atob(encryptedId);
            getOrderDetails(originalId)
                .then(res => {
                    if (res.data.status) {
                        setOrderDetails(res.data.response)
                        setAcceptLoading(false)
                    } else navigate(laung == "en" ? `/${laung}/account` : "/account")
                })
                .catch(e => ADMIN.toast.error(e))
        } catch {
            navigate(laung == "en" ? `/${laung}/account` : "/account")
        }
    };

    const handleUpdateOrderStatus = (orderDetail) => {
        setAcceptLoading(true)
        const data = {
            "status": "100",
            "order_vendor_id": orderDetail?.order_vendor_id
        }
        updateOrderStatus(data)
            .then(res => {
                if (res.data.status) {
                    handleGetOrderDetails()
                    ADMIN.toast.success(res.data.response.message)
                } else (ADMIN.toast.error(res.data.response.message))
            })
            .catch(e => ADMIN.toast.error(e))
    };

    const handleRejectOrder = (orderDetail) => {
        if (!vendorReason || rejectLoader) {
            return
        } else {
            setRejectLoader(true)
            const data = {
                "status": "106",
                "order_vendor_id": orderDetail?.order_vendor_id,
                "reason": vendorReason,
            }
            updateOrderStatus(data)
                .then(res => {
                    if (res.data.status) {
                        handleGetOrderDetails()
                        // setOrderCancel(false)
                        ADMIN.toast.success(res.data.response.message)
                    } else (ADMIN.toast.error(res.data.response.message))
                })
                .catch(e => ADMIN.toast.error(e))
        }
    }
    const handleChat = () => {
        setModal(true)
        dispatch(singelOrderChatDetails(ovid))
    };

    const handleDownload = async () => {
        try {
            const response = await axios.get(
                orderDetail.quotation,
                { responseType: 'blob' }
            );
            const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
            saveAs(pdfBlob, `${orderDetail?.order_number}.pdf`);
        } catch (error) {
            console.error(error);
        }
    };

    const handleDownloadTaxInvoice = async () => {
        try {
            const response = await axios.get(
                orderDetail.customer_invoice,
                { responseType: 'blob' }
            );
            const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
            saveAs(pdfBlob, `${"C"}${orderDetail?.order_number}.pdf`);
        } catch (error) {
            console.error(error);
        }
    };

    const navigateToProduct = (product) => {
        if (laung == "en") {
            navigate(`/${laung}/${product.url == "" ? "p" : product.url}/p/${product.product_id}`, {
                state: {
                    search: 0
                }
            })
        } else {
            navigate(`/${product.url == "" ? "p" : product.url}/p/${product.product_id}`, {
                state: {
                    search: 0
                }
            })
        }
    };

    const handleClose = () => {
        setModal(false)
    };

    const handlePaymentMethods = (type) => {
        const data = {
            "payment_type": type,
            "order_vendor_id": orderDetail?.order_vendor_id
        }
        if (type === 1) {
            updateOrderStatus(data)
                .then(res => {
                    if (res.data.status) {
                        setIbanModal(true)
                        ADMIN.toast.success(res.data.response.message)
                        return
                    } else (ADMIN.toast.error(res.data.response.message))
                })
                .catch(e => ADMIN.toast.error(e))
        }
        if (type === 2) {
            setLoading(true);
            updateOrderStatus(data)
                .then(res => {
                    if (res.data.status) {
                        if (res.data.response.payment_url) {
                            window.location.href = res.data.response.payment_url;
                            setLoading(false);
                        } else (ADMIN.toast.error(res.data.response.payment_response.message))
                        return
                    } else {
                        setLoading(false);
                        ADMIN.toast.error(res.data.response.message)
                    }
                })
                .catch(e => { setLoading(false); ADMIN.toast.error(e) })
        }
    }

    const closeIbanModal = () => {
        setIbanModal(false)
    }

    const handleCopingIBAN = (iabn) => {
        navigator.clipboard.writeText(iabn)
        ADMIN.toast.success("IBAN is Copied")
        setIbanModal(false)
    }

    const handleTrackshipment = () => {
        window.open(orderDetail.falcon_tracking, "_blank")
    };

    const handleVendorReason = (e) => {
        e.preventDefault();
        setVendorReason(e.target.value);
    };

    function formatDate2(dateString) {
        const date = new Date(dateString);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return new Intl.DateTimeFormat('en-GB', options).format(date);
    }

    // submit vendot comment
    const handleSubmitComment = () => {
        const data = {
            "order_vendor_id": orderDetail.order_vendor_id,
            "rating": orderRating,
            "comment": comment,
        }

        vendorRatting(data).then(res => {
            if (res.data.status) {
                ADMIN.toast.success(res.data.response.message)
                handleGetOrderDetails(ovid)
            }
        })
            .catch(e => {
                ADMIN.toast.error(e)
            })

    };

    // submit product rating
    const handleSubmitProductRating = (newValue, oDID) => {
        const data = {
            "order_detail_id": oDID,
            "rating": newValue,
        }

        orderProductRatting(data).then(res => {
            if (res.data.status) {
                ADMIN.toast.success(res.data.response.message)
                handleGetOrderDetails(ovid)
            }
        })
            .catch(e => {
                ADMIN.toast.error(e)
            })
    };
    const totalOrdeQuantity = orderDetail && Array.isArray(orderDetail.order_detail) && orderDetail.order_detail.map((item) => Number(item.quantity)).reduce((accumulator, currentValue) => accumulator + currentValue, 0)

    // jSX------------------------------------------------------------------------
    return (
        <>
            {
                loading ? <SpiningLoader /> :
                    orderDetail ?
                        <div className={styles.order_container} >
                            <div className={styles.order_name} >
                                <img src={laung == "ar" ? ChevRight : ChevLeft} alt=""
                                    style={{ marginRight: "15px", cursor: "pointer" }}
                                    onClick={() => navigate(-1)}
                                />
                                <h6 className={styles.on_process}
                                    style={{
                                        backgroundColor: orderDetail.status == "105" ? "#456ED610" :
                                            orderDetail.status == "100" ? "#93c9af99" :
                                                orderDetail.status == "91" ? "#958559" : "#FF3D0010",
                                        color: orderDetail.status == "105" ? "#958559" :
                                            orderDetail.status == "100" ? "#1F965D" :
                                                orderDetail.status == "91" ? "#FFFFFF" : "#FF3D00",
                                        fontFamily: laung == "ar" ? "Cairo-Medium" : "Poppins-Medium"
                                    }}
                                >{orderDetail?.status_title}</h6>
                                {orderDetail?.created_on && <p>{formatDate2(orderDetail?.created_on)}</p>}
                                <p>{orderDetail?.order_number}</p>

                            </div >
                            <div className={laung == "ar" ? styles.ar_order_section : styles.order_section}>
                                <div className={styles.order_first_left}>
                                    <h6 style={{ fontFamily: laung == "ar" ? "Cairo-Medium" : "Poppins-Medium" }}> {t("ORDER NO")}. {orderDetail?.order_number}</h6>
                                    <p style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "Poppins-Regular" }}>{totalOrdeQuantity} {t("items")} - {t("total")}: {orderDetail.total} SAR</p>
                                </div>
                                <div className={styles.order_first_right}>
                                    <h2 style={{ fontFamily: laung == "ar" ? "Cairo-Medium" : "Poppins-Medium" }}>{t("total")}<span>{orderDetail.total} SAR</span></h2>
                                </div>
                            </div>

                            <div className={styles.ordersection_row}>
                                <div className={styles.shipping_details}>

                                    <div className={styles.quotation_button}>
                                        {orderDetail.quotation && <button style={{ fontFamily: laung == "ar" ? "Cairo-Medium" : "Poppins-Medium" }} onClick={() => handleDownload()} >{t("VL QUOTATION")} <img src={Download} alt="" /></button>}
                                        {orderDetail.customer_invoice && <button style={{ fontFamily: laung == "ar" ? "Cairo-Medium" : "Poppins-Medium" }} onClick={() => handleDownloadTaxInvoice()} >{t("TAX INVOICES")} <img src={Download} alt="" /></button>}
                                    </div>
                                    <div className={styles.track_order}>
                                        {orderDetail.falcon_barcode &&
                                            <button style={{ fontFamily: laung == "ar" ? "Cairo-Medium" : "Poppins-Medium" }} className={styles.tracking_order} onClick={() => setShowTracking(!showTracking)}>
                                                <span><img src={track} alt="" /> {t("TRACKING YOUR ORDER")} </span>
                                                <img style={{ transform: showTracking ? "rotate(0deg)" : "rotate(180deg)" }} src={tabarrow} alt="" className={styles.tabarrow} />
                                            </button>
                                        }
                                        {orderDetail.payment_status == "1" &&
                                            <button style={{ fontFamily: laung == "ar" ? "Cairo-Medium" : "Poppins-Medium" }} className={styles.tracking_order} onClick={() => setShowpaymentDetails(!showPaymentDetails)} >
                                                <span style={{ fontFamily: laung == "ar" ? "Cairo-Medium" : "Poppins-Medium" }} ><img src={paymenttrack} alt="" /> {t("PAYMENT DETAILS")} </span>
                                                <img style={{ transform: showPaymentDetails ? "rotate(0deg)" : "rotate(180deg)" }} src={tabarrow} alt="" className={styles.tabarrow} />
                                            </button>
                                        }
                                    </div>

                                </div>
                                {showTracking &&
                                    <div className={styles.shipping_detailscenter}>
                                        <h4 style={{ fontFamily: laung == "ar" ? "Cairo-Medium" : "Poppins-Medium" }}>{t("SHIPPING DETAILS")}</h4>
                                        <h6 style={{ fontFamily: laung == "ar" ? "Cairo-Medium" : "Poppins-Medium" }}>{t("TRACKING DETAILS")}</h6>
                                        <div className={styles.order_tracking}>
                                            <h5 style={{ fontFamily: laung == "ar" ? "Cairo-Medium" : "Poppins-Medium" }}>{t("TRACKING YOUR ORDER")}</h5>
                                            <div className={styles.shipping_detailswrapper}>
                                                <div className={styles.circle_back}><span></span></div>
                                                <div className={styles.date_time}>
                                                    <h3 style={{ fontFamily: laung == "ar" ? "Cairo-Medium" : "Poppins-Medium" }}>{t("Shipment Processed by")} X Shipping {t("Company")}</h3>
                                                    <p style={{ fontFamily: laung == "ar" ? "Cairo-Medium" : "Poppins-Medium" }}>{t("Date")}: June 15, 2024 </p>
                                                    <p style={{ fontFamily: laung == "ar" ? "Cairo-Medium" : "Poppins-Medium" }}>{t("Time")}: 10:30 AM GMT</p>
                                                </div>
                                            </div>
                                            <div className={styles.shipping_detailswrapper}>
                                                <div className={styles.circle_back}><span></span></div>
                                                <div className={styles.date_time}>
                                                    <h3 style={{ fontFamily: laung == "ar" ? "Cairo-Medium" : "Poppins-Medium" }}>{t("On Delivery by")} X Shipping {t("Company")}</h3>
                                                    <p style={{ fontFamily: laung == "ar" ? "Cairo-Medium" : "Poppins-Medium" }}>{t("Date")}: June 15, 2024 </p>
                                                    <p style={{ fontFamily: laung == "ar" ? "Cairo-Medium" : "Poppins-Medium" }}>{t("Time")}: 10:30 AM GMT</p>
                                                </div>
                                            </div>
                                            <div className={styles.shipping_detailswrapper}>
                                                <div className={styles.circle_back_active}><span></span></div>
                                                <div className={styles.date_time}>
                                                    <h3 style={{ fontFamily: laung == "ar" ? "Cairo-Medium" : "Poppins-Medium" }}>{t("In Transit by")} X Shipping {t("Company")}</h3>
                                                    <p style={{ fontFamily: laung == "ar" ? "Cairo-Medium" : "Poppins-Medium" }}>{t("Date")}: June 15, 2024 </p>
                                                    <p style={{ fontFamily: laung == "ar" ? "Cairo-Medium" : "Poppins-Medium" }}>{t("Time")}: 10:30 AM GMT</p>
                                                </div>
                                            </div>
                                            {orderDetail.falcon_barcode &&
                                                <button className={styles.track_shipment_btn} onClick={() => handleTrackshipment()} style={{ cursor: "pointer" }} >{t("Track Now")}</button>
                                            }
                                        </div>
                                    </div>
                                }
                                {showPaymentDetails &&
                                    <div className={styles.shipping_detailscenter}>
                                        <h4 style={{ fontFamily: laung == "ar" ? "Cairo-Medium" : "Poppins-Medium" }}>{t("PAYMENT DETAILS")}</h4>
                                        <h6 style={{ fontFamily: laung == "ar" ? "Cairo-Medium" : "Poppins-Medium" }} >{t("BANK DETAILS")}</h6>
                                        <div className={styles.order_tracking}>
                                            <h5 style={{ fontFamily: laung == "ar" ? "Cairo-Medium" : "Poppins-Medium" }} >{t("YOUR PAYMENT METHOD")}</h5>
                                            <h1 style={{ fontFamily: laung == "ar" ? "Cairo-Medium" : "Poppins-Medium" }} ><span style={{ fontFamily: laung == "ar" ? "Cairo-Medium" : "Poppins-Medium" }}>{t('YOUR PAYMENT METHOD')} </span>: {orderDetail.tid ? t("Credit/Debit Card") : t("Bank Method")}</h1>
                                            <h1 style={{ fontFamily: laung == "ar" ? "Cairo-Medium" : "Poppins-Medium" }} ><span style={{ fontFamily: laung == "ar" ? "Cairo-Medium" : "Poppins-Medium" }}>{t("TRANSACTION DATE")}</span> : <div className={styles.date_timing}><span><img src={calendar} alt="" /> {formatDate2(orderDetail && orderDetail.payment_date)} </span>   <span><img src={clock} alt="" /> {orderDetail && orderDetail.payment_date.split(" ")[1]}</span></div></h1>
                                            {orderDetail.tid && <h1><span style={{ fontFamily: laung == "ar" ? "Cairo-Medium" : "Poppins-Medium" }}>{t('TRANSACTION ID')}</span> : {orderDetail.tid}</h1>}
                                        </div>
                                    </div>}
                            </div>
                            {(orderDetail.status == "100" && orderDetail.payment_status === "0") &&
                                <div className={styles.payment_details} >
                                    <div className={styles.payment_through} >
                                        <AccountBalanceIcon style={{ fontSize: "45px" }} color='#2a2821' />
                                        <H3>{t("Payment Through")} : </H3>
                                    </div>

                                    <div className={styles.req_button}  >
                                        <div className={styles.chat_count} >
                                            <p className={styles.accept_order} onClick={() => handlePaymentMethods(2)} style={{ fontFamily: laung == "ar" && "Cairo-Semibold" }} >{t("Payment Gateway")}</p>
                                        </div>

                                        <div className={styles.chat_count} >
                                            <p className={styles.accept_order} onClick={() => handlePaymentMethods(1)} style={{ fontFamily: laung == "ar" && "Cairo-Semibold" }} >{t("To Bank Account")}</p>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className={styles.quatation_details} >
                                <div className={styles.download_request} >
                                    <div className={styles.download_quoation} >
                                        {orderDetail.quotation &&
                                            <div className={styles.request_accept_section} >

                                                <div className={styles.req_button}  >
                                                    {
                                                        (orderDetail.status == "100" || orderDetail.status == "105") &&
                                                        <>
                                                            <h1 style={{ fontFamily: laung == "ar" ? "Cairo-Medium" : "Poppins-Medium" }}> {t("Bank Transfer Document")}</h1>
                                                            <div className={styles.chat_count} onClick={() => handleChat()}>
                                                                {orderDetail.new_chat > 0 && <p className={styles.count_number} >{orderDetail.new_chat}</p>}
                                                                <img src={transfericon} alt="" />
                                                                <p style={{ fontFamily: "Cairo-Semibold" }} >{t("Upload Bank Transfer Document")}</p>
                                                            </div>
                                                        </>
                                                    }
                                                    {
                                                        orderDetail.status === "91" &&
                                                        <div style={{ display: "flex", columnGap: "10px", width: "100%" }} >
                                                            <p className={styles.accept_order} onClick={() => setOrderCancel(true)} style={{ fontFamily: "Cairo-Semibold" }}> {t("reject order")} </p>
                                                            <p className={styles.accept_order} onClick={() => handleUpdateOrderStatus(orderDetail)} style={{ fontFamily: "Cairo-Semibold" }}> {acceptLoading ? <Spinner /> : t("accept order")} </p>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        <p className={styles.items_in_order} style={{ fontFamily: "Cairo-Bold" }} >{t("items in this order")}</p>
                                        <div className={styles.items_list} > {
                                            orderDetail?.order_detail?.map((data, indi) => {
                                                return (
                                                    <div className={styles.order_item}
                                                        key={indi} >
                                                        <div className={styles.item_info}  >
                                                            <img
                                                                className={styles.product_pic}
                                                                src={data?.image}
                                                                alt="item"
                                                                onClick={() => navigateToProduct(data)}
                                                            />
                                                            <div>
                                                                <h4 className={styles.item_name} style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "Poppins-SemiBold" }} >{data?.product_name}</h4>
                                                                <h6 style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "Poppins-Regular" }} >{`${data?.unit_price}`} SAR</h6>
                                                                {data.weight && <p style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "Poppins-Regular" }}>{data.weight} {t("grams")}</p>}
                                                                {data.status !== "107" && (orderDetail?.status === "100" || orderDetail?.status === "105") ? data.given_rating ?
                                                                    <Rating
                                                                        initialRating={data.given_rating}
                                                                        fractions={2}
                                                                        readonly
                                                                        direction={laung == "ar" ? "rtl" : "ltr"}
                                                                        emptySymbol={<img src={InActiveStar} alt="" className="icon" />}
                                                                        fullSymbol={<img src={ActiveStar} alt="" className="icon" />}
                                                                    />
                                                                    :
                                                                    <Rating
                                                                        initialRating={orderRating}
                                                                        fractions={2}
                                                                        onChange={(event, newValue) => {
                                                                            handleSubmitProductRating(event, data.order_detail_id);
                                                                        }}
                                                                        direction={laung == "ar" ? "rtl" : "ltr"}
                                                                        emptySymbol={<img src={InActiveStar} alt="" className="icon" />}
                                                                        fullSymbol={<img src={ActiveStar} alt="" className="icon" />}
                                                                    /> : ""
                                                                }
                                                            </div>

                                                        </div>

                                                        {orderDetail.status !== "107" && <p className={styles.no_items} style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "Poppins-Medium" }} >{data.status === "107" ? t("out of stock") : ` ${data?.quantity} ${t("items")}`}  </p>}
                                                    </div>
                                                )
                                            })
                                        }
                                        </div>
                                        <div className={styles.order_Address} >
                                            <p className={styles.address_label} style={{ fontFamily: "Cairo-Bold" }} >{t("delivery address")}</p>
                                            <p className={styles.name_number} style={{ fontFamily: "Cairo-Bold" }} > {orderDetail?.order_address?.first_name} {orderDetail?.order_address?.last_name} <span style={{ textAlign: laung === "ar" ? "right" : "left" }}>{orderDetail?.order_address?.country_code} {orderDetail?.order_address?.phone_number}</span></p>
                                            <p className={styles.location} style={{ fontFamily: "Cairo-Semibold" }} >{orderDetail?.order_address?.address} {orderDetail?.order_address?.postal_code}</p>
                                        </div>
                                        <div className={styles.order_prices} >
                                            <div className={styles.prices_left_side} >
                                                <div className={styles.order_price} >
                                                    <p className={styles.price_label} style={{ fontFamily: "Cairo-Bold" }} >{t("total order price")}</p>
                                                    <div className={styles.price_fare} >
                                                        <p className={styles.price} style={{ fontFamily: "Cairo-Bold" }} > {orderDetail?.currency} {orderDetail?.total}</p>
                                                    </div>
                                                </div>
                                                <div className={styles.price_break_up} >
                                                    <p className={styles.summary_label} style={{ fontFamily: "Cairo-Bold" }}>{t("summary")}</p>
                                                    <div className={styles.price_category} >
                                                        <p className={styles.category_label} style={{ fontFamily: "Cairo-Semibold" }} >{t("subtotal")}</p>
                                                        <p className={styles.category_price} style={{ fontFamily: "Cairo-Semibold" }} > SAR {orderDetail.length != 0 && (orderDetail.sub_total).toLocaleString("en-US", { style: "currency", currency: "SAR" })}</p>
                                                    </div>
                                                    {(orderDetail.bill_value_discount > 0 || orderDetail.discount > 0) && <div className={styles.price_category} >
                                                        <p className={styles.category_label} style={{ fontFamily: "Cairo-Semibold" }}>{t("discount")}:</p>
                                                        <p className={styles.category_price} style={{ fontFamily: "Cairo-Semibold" }}> SAR {orderDetail.length != 0 && (Number(orderDetail.bill_value_discount) + Number(orderDetail.discount))}</p>
                                                    </div>}
                                                    {Number(orderDetail.extra_discount) > 0 && <div className={styles.price_category} >
                                                        <p className={styles.category_label} style={{ fontFamily: "Cairo-Semibold" }}>{t("Extra Discount")}:</p>
                                                        <p className={styles.category_price} style={{ fontFamily: "Cairo-Semibold" }}> SAR {orderDetail.length != 0 && Number(orderDetail.extra_discount)}</p>
                                                    </div>}
                                                    <div className={styles.price_category} >
                                                        <p className={styles.category_label} style={{ fontFamily: "Cairo-Semibold" }}>{t("vat 18%")} :({orderDetail.length != 0 && orderDetail.tax_percent} %)</p>
                                                        <p className={styles.category_price} style={{ fontFamily: "Cairo-Semibold" }}> SAR {orderDetail.length != 0 && (orderDetail.tax).toLocaleString("en-US", { style: "currency", currency: "SAR" })}</p>
                                                    </div>
                                                    {Number(orderDetail.shipping_charge) > 0 && <div className={styles.price_category} >
                                                        <p className={styles.category_label} style={{ fontFamily: "Cairo-Semibold" }}>{t("shipping charge")}:</p>
                                                        <p className={styles.category_price} style={{ fontFamily: "Cairo-Semibold" }}> SAR {orderDetail.length != 0 && Number(orderDetail.shipping_charge)}</p>
                                                    </div>}
                                                    {Number(orderDetail.shipping_tax) > 0 && <div className={styles.price_category} >
                                                        <p className={styles.category_label} style={{ fontFamily: "Cairo-Semibold" }}>{t("Shipping Tax")}:</p>
                                                        <p className={styles.category_price} style={{ fontFamily: "Cairo-Semibold" }}> SAR {orderDetail.length != 0 && Number(orderDetail.shipping_tax)}</p>
                                                    </div>}
                                                    {Number(orderDetail.shipping_discount) > 0 && <div className={styles.price_category} >
                                                        <p className={styles.category_label} style={{ fontFamily: "Cairo-Semibold" }}>{t("shipping discount")}:</p>
                                                        <p className={styles.category_price} style={{ fontFamily: "Cairo-Semibold" }}> SAR {orderDetail.length != 0 && Number(orderDetail.shipping_discount)}</p>
                                                    </div>}

                                                    {Number(orderDetail.extra_shipping_discount) > 0 && <div className={styles.price_category} >
                                                        <p className={styles.category_label} style={{ fontFamily: "Cairo-Semibold" }}>{t("Extra Shipping Discount")}:</p>
                                                        <p className={styles.category_price} style={{ fontFamily: "Cairo-Semibold" }}> SAR {orderDetail.length != 0 && Number(orderDetail.extra_shipping_discount)}</p>
                                                    </div>}
                                                    <div className={styles.price_category} >
                                                        <p className={styles.category_label} style={{ fontFamily: "Cairo-Semibold" }}>{t("total")}:</p>
                                                        <p className={styles.category_price} style={{ fontFamily: "Cairo-Semibold" }}> SAR {orderDetail.length != 0 && (orderDetail.total).toLocaleString("en-US", { style: "currency", currency: "SAR" })}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {(orderDetail?.status === "100" || orderDetail?.status === "105") ?
                                            orderDetail.given_rating ?
                                                <>
                                                    <h3 style={{ fontFamily: laung == "ar" ? "Cairo-Semibold" : "Poppins-SemiBold" }}>{t("Order Rating")}  </h3>
                                                    <Rating
                                                        initialRating={orderDetail.given_rating}
                                                        fractions={0.5}
                                                        readonly
                                                        direction={laung == "ar" ? "rtl" : "ltr"}
                                                        emptySymbol={<img src={InActiveStar} alt="" className="icon" style={{ height: "25px", width: "25px" }} />}
                                                        fullSymbol={<img src={ActiveStar} alt="" className="icon" style={{ height: "25px", width: "25px" }} />}
                                                    />
                                                </>
                                                :
                                                <div className={styles.rate_vendor}>
                                                    <h3 style={{ fontFamily: laung == "ar" ? "Cairo-Semibold" : "Poppins-SemiBold" }}>{t("Rate This Order")}  </h3>
                                                    <Rating
                                                        initialRating={orderRating}
                                                        fractions={0.5}
                                                        onChange={(event, newValue) => {
                                                            setOrderOrderRating(event);
                                                        }}
                                                        direction={laung == "ar" ? "rtl" : "ltr"}
                                                        emptySymbol={<img src={InActiveStar} alt="" className="icon" style={{ height: "25px", width: "25px" }} />}
                                                        fullSymbol={<img src={ActiveStar} alt="" className="icon" style={{ height: "25px", width: "25px" }} />}
                                                    />
                                                    <div>
                                                        <textarea
                                                            placeholder={t('Let know your feedback')}
                                                            defaultValue={comment}
                                                            value={comment}
                                                            onChange={(e) => setComment(e.target.value)}
                                                            style={{ fontFamily: laung == "ar" ? "Cairo-Medium" : "Poppins-Medium" }}
                                                        />
                                                    </div>
                                                    <button
                                                        disabled={!comment && !orderRating}
                                                        onClick={() => handleSubmitComment()}
                                                        className={styles.post_comment}
                                                        style={{ fontFamily: laung == "ar" ? "Cairo-Semibold" : "Poppins-SemiBold" }}>{t("send")}</button>
                                                </div> :
                                            ""}
                                    </div>
                                </div>
                            </div>
                        </div> :
                        <p style={{ fontFamily: "Cairo-Semibold" }}>{t("loading")}</p>
            }
            <Dialog
                maxWidth="lm"
                open={openModal}
                onClose={handleClose}
            >
                <ChatModal orderData={orderDetail} laung={laung} />
            </Dialog>
            <Modal
                open={ibanModal}
                onClose={() => closeIbanModal()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalstyle}>
                    <H3>{t("IBAN Number")}</H3>
                    {orderDetail.iban ? <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", cursor: "pointer" }} >
                        <P>{orderDetail.iban}</P>
                        <ContentCopyIcon fontSize='medium' onClick={() => handleCopingIBAN(orderDetail.iban)} />
                    </div> : <P>{t("IBAN is Not Availabele")}</P>}
                </Box>
            </Modal>
            <ReactModal
                isOpen={orderCancel}
                onRequestClose={() => setOrderCancel(state => !state)}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className={styles.cancel_order} >
                    <H3>{t("Let Us Know the reason for cancel")}</H3>
                    <textarea
                        style={{ width: "100%" }}
                        rows={4}
                        placeholder={t("Write Here")}
                        value={vendorReason}
                        required
                        onChange={(e) => handleVendorReason(e)}
                    />
                    <div className={styles.cancel_buttons} >
                        <p className={styles.cancel_order_btn} onClick={() => setOrderCancel(false)}> {t("Cancel")} </p>
                        <p className={styles.accept_order} onClick={() => vendorReason && handleRejectOrder(orderDetail)}> {rejectLoader ? <Spinner /> : t("update")} </p>
                    </div>
                </div>
            </ReactModal>
        </>
    )
}

export default Orders