import React, { useState } from 'react'
import { Route, Routes, } from 'react-router-dom';
import MainHomePage from '../Components/MainHomePage/MainHomePage';
import Register from '../Components/Login/Register/Register';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SellersProductList from '../Components/sellersDetails/SellersProductList';
import SingleSellerDetails from '../Components/sellersDetails/SingleSellerDetails';
import ThankYou from '../Components/Login/Register/seller/ThankYou';
import Account from '../Components/account/Account';
import Orders from '../Components/Profile/orders/Orders';
import RequestSubmitted from '../Components/kart/RequestSubmitted';
import SelectAddressModal from '../Components/Modal/SelectAddressModal';
import DepartmentTabwithCategoryList from '../Components/LandingThree/DepartmentTabwithCategoryList/DepartmentTabwithCategoryList';
import NotFoundPage from './NotFound/NotFoundPage';
import PaymentSuccess from '../Components/Profile/orders/payment/PaymentSuccess';
import PaymentFailed from '../Components/Profile/orders/payment/PaymentFailed';
import ForgotPassword from '../Components/Login/Password/ForgotPassword';
import CreateNewPassword from '../Components/Login/Password/CreateNewPassword';
import WithHeaderRoutes from './WithHeaderRoutes';
import RecentlyViewed from '../Components/recentlyviewed/RecentlyViewed';
import PendingReviews from '../Components/PendingReviews/PendingReviews';


const AboutUsMain = React.lazy(() => import('../Components/Terms&conditions/AboutUsMain'));
const OurStoryMain = React.lazy(() => import('../Components/Terms&conditions/OurStoryMain'));
const FAQMain = React.lazy(() => import('../Components/Terms&conditions/FAQMain'));
const TermsConditionsMain = React.lazy(() => import('../Components/Terms&conditions/TermsConditionsMain'));
const PrivacyPolicyMain = React.lazy(() => import('../Components/Terms&conditions/PrivacyPolicyMain'));
const LandingThreeMain = React.lazy(() => import('../Components/LandingThree/LandingThreeMain'));
const LoginMain = React.lazy(() => import('../Components/Login/LoginMain'));
const SellerRegisOneMain = React.lazy(() => import('../Components/Login/Register/seller/SellerRegisOneMain'));
const VendorRegisterStepOneMain = React.lazy(() => import('../Components/Login/Register/VendorRegister/VendorRegisterStepOneMain'));
const CartMain = React.lazy(() => import('../Components/kart/CartMain'));
const MyChatMain = React.lazy(() => import('../Components/Profile/chat/MyChatMain'));
const UserProfileMain = React.lazy(() => import('../Components/Profile/userprofile/UserProfileMain'));
const SavedAddressMain = React.lazy(() => import('../Components/address/SavedAddressMain'));
const WishlistMain = React.lazy(() => import('../Components/wishlist/WishlistMain'));
const SingleProductMain = React.lazy(() => import('../Components/SingleProduct/SingleProductMain'));
const BlogDetailsMain = React.lazy(() => import('../Components/Blogs/BlogDetailsMain'));
const BlogListingMain = React.lazy(() => import('../Components/Blogs/BlogListingMain'));
const ViewAllMain = React.lazy(() => import('../Components/ViewAll/ViewAllMain'));



const AppRouter = () => {
  const [show, setShow] = useState('');
  const laung = localStorage.getItem('laung');
  const [theIndustry, setIndutry] = useState("");
  return (
    <>
      <ToastContainer className="unselectable" />
      {/* <LandingHeaderThree setIndutry={setIndutry} /> */}
      <Routes>
      <Route path={laung == 'en' ? `/${laung}/forgotpassword` : "/forgotpassword"} element={<ForgotPassword />} />
        <Route path={laung == 'en' ? `/${laung}/newpassword` : "/newpassword"} element={<CreateNewPassword />} />
        <Route path={laung == 'en' ? `/${laung}/login` : "/login"} element={<LoginMain />} />
        <Route path={laung == 'en' ? `/${laung}` : "/"} element={<WithHeaderRoutes><LandingThreeMain theIndutry={theIndustry} /></WithHeaderRoutes>} key="/" />
        <Route path={laung == "en" ? `${laung}/category` : "/category"} element={<WithHeaderRoutes><DepartmentTabwithCategoryList /></WithHeaderRoutes>} />
        <Route path={laung == "en" ? `${laung}/account` : "account"} element={<WithHeaderRoutes><Account /></WithHeaderRoutes>} >
          <Route path={laung == "en" ? `/${laung}/account` : "/account"} element={<UserProfileMain />} />
          <Route path="order_status/:ovid" element={<Orders />} />
          <Route path="my_profile" element={<UserProfileMain />} />
          <Route path="saved_address" element={<SavedAddressMain />} />
          <Route path="chats" element={<MyChatMain />} />
        </Route>
        <Route path={laung == "en" ? `${laung}/all_products/:offertype/:offerkey` : "all_products/:offertype/:offerkey"} element={<WithHeaderRoutes><ViewAllMain /></WithHeaderRoutes>} />
        <Route path={laung == "en" ? `${laung}/category/:categoryname/*` : "/category/:categoryname/*"} element={<WithHeaderRoutes><MainHomePage /></WithHeaderRoutes>} />
        <Route path={laung == "en" ? `${laung}/all_products/vendor/:vendor_id` : "all_products/vendor/:vendor_id"} element={<WithHeaderRoutes><ViewAllMain /></WithHeaderRoutes>} />
        <Route path={laung == "en" ? `${laung}/vendor/all_products/:vendor_id` : "vendor/all_products/:vendor_id"} element={<WithHeaderRoutes><ViewAllMain /></WithHeaderRoutes>} />
        <Route path={laung == "en" ? `${laung}/:producturl/p/:productID` : "/:producturl/p/:productID"} element={<WithHeaderRoutes><SingleProductMain /></WithHeaderRoutes>} />

        <Route path={laung == "en" ? `${laung}/wish_list` : "/wish_list"} element={<WithHeaderRoutes><WishlistMain /></WithHeaderRoutes>} />
        <Route path={laung == "en" ? `${laung}/recent_viewed` : "/recent_viewed"} element={<WithHeaderRoutes><RecentlyViewed /></WithHeaderRoutes>} />
        <Route path={laung == "en" ? `${laung}/pending_reviews` : "/pending_reviews"} element={<WithHeaderRoutes><PendingReviews /></WithHeaderRoutes>} />
        <Route path={laung == 'en' ? `${laung}/my_cart` : "/my_cart"} element={<WithHeaderRoutes><CartMain /></WithHeaderRoutes>} />
        <Route path={laung == "en" ? `${laung}/select_address` : "/select_address"} element={<WithHeaderRoutes><SelectAddressModal /></WithHeaderRoutes>} />
        <Route path={laung == "en" ? `${laung}/order_submited` : "/order_submited"} element={<WithHeaderRoutes><RequestSubmitted /></WithHeaderRoutes>} />
        <Route path={laung == "en" ? `${laung}/sellers_list` : "sellers_list"}  >
          <Route path={laung == "en" ? `/${laung}/sellers_list` : "/sellers_list"} element={<WithHeaderRoutes><SellersProductList /></WithHeaderRoutes>} />
          <Route path="seller_details/:sid" element={<WithHeaderRoutes><SingleSellerDetails /></WithHeaderRoutes>} />
          <Route path="thank_you" element={<WithHeaderRoutes><ThankYou /></WithHeaderRoutes>} />
        </Route>

        <Route path={laung == "en" ? `${laung}/registration` : "registration/"} element={<Register />} />
        <Route path={laung == "en" ? `${laung}/seller_register` : "seller_register"} >
          <Route path={laung == "en" ? `/${laung}/seller_register` : "/seller_register"} element={<SellerRegisOneMain />} />
          <Route path="thank_you" element={<ThankYou />} />
        </Route>
        <Route path={laung == "en" ? `${laung}/vendor_register` : "vendor_register"} element={<VendorRegisterStepOneMain />} />
        <Route path="thank_you" element={<ThankYou />} />
        <Route path={laung == "en" ? `${laung}/about_us` : "about_us"} element={<WithHeaderRoutes><AboutUsMain /></WithHeaderRoutes>} />
        <Route path={laung == "en" ? `${laung}/our_story` : "our_story"} element={<WithHeaderRoutes><OurStoryMain /></WithHeaderRoutes>} />
        <Route path={laung == "en" ? `${laung}/faqs` : "faqs"} element={<WithHeaderRoutes><FAQMain /></WithHeaderRoutes>} />
        <Route path={laung == "en" ? `${laung}/terms_conditions/:type` : "terms_conditions/:type"} element={<WithHeaderRoutes><TermsConditionsMain /></WithHeaderRoutes>} />
        <Route path={laung == "en" ? `${laung}/privacy_policy` : "privacy_policy"} element={<WithHeaderRoutes><PrivacyPolicyMain /></WithHeaderRoutes>} />
        <Route path={laung == "en" ? `${laung}/blogs` : "blogs"} element={<WithHeaderRoutes><BlogListingMain /></WithHeaderRoutes>} />
        <Route path={laung == "en" ? `${laung}/:blogurl/b/:id` : "/:blogurl/b/:id"} element={<WithHeaderRoutes><BlogDetailsMain /></WithHeaderRoutes>} />
        <Route path={laung == "en" ? `${laung}/payment-success/:ovid` : "/payment-success/:ovid"} element={<WithHeaderRoutes><PaymentSuccess /></WithHeaderRoutes>} />
        <Route path={laung == "en" ? `${laung}/payment-failed/:ovid` : "/payment-failed/:ovid"} element={<WithHeaderRoutes><PaymentFailed /></WithHeaderRoutes>} />
        <Route path="/notfoundpage" element={<WithHeaderRoutes><NotFoundPage setShow={setShow} /></WithHeaderRoutes>} />
      </Routes>
    </>
  )
}

export default AppRouter;
