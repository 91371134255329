import React, { useEffect, useState } from 'react';
import styles from './Footer.module.scss'
import Help from "../../../assets/Images/pngs/wsp_icon.png";
import Call from "../../../assets/Images/svgs/call-icon.svg";
import twitter from "../../../assets/Images/svgs/twitter.svg";
import instagram from "../../../assets/Images/svgs/instagram.svg";
import linkedin from "../../../assets/Images/svgs/linkedin.svg";
import googleplay from "../../../assets/Images/svgs/google-play.svg";
import appstore from "../../../assets/Images/svgs/app-store.svg";
import right1 from '../../../assets/Images/svgs/right-1.svg';
import right2 from '../../../assets/Images/svgs/right-2.svg';
import right3 from '../../../assets/Images/svgs/right-3.svg';
import mada from "../../../assets/Images/svgs/mada.svg";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BG from "../../../assets/Images/svgs/up_arrow_icon.svg";
import { getUserDetails } from '../../../services/Profile';
import { fetchHomepageDetails } from '../../../redux/slice/departmentSlice';
import { seoPageDetails } from '../../../redux/slice/seodetailsSlice';
import Linkedin from "../../../assets/Images/pngs/Linkedin.png";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import VAT from "../../../assets/Images/pngs/vat_icon.png";
import CRICON from "../../../assets/Images/cr_icon.jpg";
import SaudiBusineeIcon from "../../../assets/Images/pngs/saudibusiness.jpg";
import Modal from "react-modal";
import VatFile from "../../../assets/Images/pngs/venderlane_vat_01.jpg";
import CRFile1 from "../../../assets/Images/pngs/cr_file_01.jpg";
import CRFile2 from "../../../assets/Images/pngs/cr_file_02.jpg";
import CRFile3 from "../../../assets/Images/pngs/cr_file_03.jpg";
import SuadiBusiness from "../../../assets/Images/pngs/Saudibusiness_01.jpg";
import CloseIcon from '../../../assets/Images/svgs/CloseIcon.svg';
import { CustomeHeaderH3, CustomeHeaderP } from '../../../customcomponents/CustomHeader';

const customStyles = {
    content: {
        position: 'absolute',
        inset: 'unset',
        width: '50%',
        left: '50%',
        // paddingTop:"10%",
        transform: 'translateX(-50%)',
    },
};

const customStyles_en = {
    content: {
        position: 'absolute',
        inset: 'unset',
        // paddingTop:"10%",
        width: '50%',
        left: '50%',
        transform: 'translateX(-50%)',
    }
}
const customStyles1 = {
    content: {
        position: 'absolute',
        inset: 'unset',
        width: '50%',
        left: '50%',
        // paddingTop:"10%",
        transform: 'unset',
    },
};

const customStyles1_en = {
    content: {
        position: 'absolute',
        inset: 'unset',
        // paddingTop:"10%",
        width: '50%',
        left: '50%',
        transform: 'tunset',
    }
}

const LatestFooter = () => {
    const { t, i18n } = useTranslation();
    const termsCondtions = useSelector((state) => state?.termsandPrivacy.data?.response);
    const navigate = useNavigate();
    const laung = localStorage.getItem("laung");
    const [visible, setVisible] = useState(false)
    const departmentDetails = useSelector((state) => state?.departMentDetails?.data?.response);
    const [vatModal, setVatModal] = useState(false);
    const [CRModal, setCrModal] = useState(false);
    const [SaudiBusiness, setSaudiBusiness] = useState(false);

    useEffect(() => {
        i18n.changeLanguage(laung);
    }, []);

    const handleToOrders = (id) => {
        getUserDetails()
            .then(res => {
                if (res.data.status) {
                    if (id == 1) {
                        navigate(laung == "en" ? `${laung}/account/my_profile` : "/account/my_profile")
                    } else if (id == 2) {
                        navigate(laung == "en" ? `${laung}/account` : "/account")
                    }
                }
            })
            .catch(e => {
                if (e.response.status == "503") {
                    navigate(laung == 'en' ? `${laung}/login` : "/login")
                }
            })
    }
    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
        }
        else if (scrolled <= 300) {
            setVisible(false)
        }
    };
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const openWhatsAppChat = (wattsapp_number) => {
        const url = `https://wa.me/${wattsapp_number}?text=${encodeURIComponent("السلام عليكم")}`;
        window.open(url, '_blank');
    };
    const dispatch = useDispatch();
    const categoryname = localStorage.getItem("categoryname");

    window.addEventListener('scroll', toggleVisible);

    const navigatetocategory = (item) => {
        localStorage.setItem("categoryname", item.url);
        localStorage.setItem("departmentID", item.id);
        localStorage.setItem("department_title", item.title);
        dispatch(seoPageDetails("landing"));
        dispatch(fetchHomepageDetails(categoryname));
        const newUrl = laung == 'en' ? `/${laung}` : "/";
        window.open(newUrl, '_blank');
        window.onload = function () {
            window.location.reload()
        }
    }

    return (
        <div className={laung == "ar" ? styles.ar_container : styles.container} >
            <div className={styles.footer_first}>
                <div className={laung == "ar" ? styles.ar_up_arrow : styles.up_arrow} style={{ display: visible ? 'flex' : 'none' }} onClick={() => scrollToTop()}  >
                    <img src={BG} alt="" />
                </div>
                <div className={laung == "ar" ? styles.ar_footer_upper : styles.footer_upper} >
                    <div className={styles.footer_left} >
                        <div className={styles.footer_upper_center} >
                            <h2 className={styles.para_h2} style={{ fontFamily: laung == "ar" ? "Cairo-Semibold" : "Lexend-Light", }} >{t("app title")} </h2>
                            <p className={styles.app_sub_heading} style={{fontFamily: laung == "ar" ? "Cairo-Regular" : "Lexend-Light",textAlign: laung == "ar" ? "right" : "left" }} >{t('venderlane subheading')} </p>
                        </div>
                    </div>
                    <div className={styles.footer_upper_center} >
                        <CustomeHeaderH3 style={{ color: "#FFFFFF" }} >{t("departments")}
                        </CustomeHeaderH3>
                        <div className={styles.footer_menu}>
                            {Array.isArray(departmentDetails) && departmentDetails.map((item, index) => (
                                <p key={index}
                                    style={{ fontFamily: laung == "ar" ? "Cairo-Semibold" : "Lexend-Light", }}
                                    className={styles.para} onClick={() => navigatetocategory(item)}  >{item.title}</p>
                            ))}
                        </div>
                    </div>
                    <div className={styles.footer_upper_center} >
                        <CustomeHeaderH3 style={{ color: "#FFFFFF", fontFamily: laung == "ar" ? "Cairo-Semibold" : "Lexend-Light", }} >{t("Support")}
                        </CustomeHeaderH3>
                        <div className={styles.footer_menu}>
                            <p style={{ fontFamily: laung == "ar" ? "Cairo-Semibold" : "Lexend-Light", }} className={styles.para} onClick={() => navigate(laung == "en" ? `/${laung}/about_us` : "/about_us")} >{t("about us")}</p>
                            <p style={{ fontFamily: laung == "ar" ? "Cairo-Semibold" : "Lexend-Light", }} className={styles.para} onClick={() => navigate(laung == "en" ? `/${laung}/our_story` : "/our_story")} >{t("our story")}</p>
                            <p style={{ fontFamily: laung == "ar" ? "Cairo-Semibold" : "Lexend-Light", }} className={styles.para} onClick={() => navigate(laung == "en" ? `/${laung}/blogs` : "/blogs")} >{t("blogs")}</p>
                            <p style={{ fontFamily: laung == "ar" ? "Cairo-Semibold" : "Lexend-Light", }} className={styles.para} onClick={() => navigate(laung == "en" ? `/${laung}/terms_conditions/3` : "/terms_conditions/3")}  >{t("faq")}</p>
                            <p style={{ fontFamily: laung == "ar" ? "Cairo-Semibold" : "Lexend-Light", }} className={styles.para} onClick={() => navigate(laung == "en" ? `/${laung}/terms_conditions/1` : "/terms_conditions/1")}  >{t("terms & conditions")}</p>
                            <p style={{ fontFamily: laung == "ar" ? "Cairo-Semibold" : "Lexend-Light", }} className={styles.para} onClick={() => navigate(laung == "en" ? `/${laung}/terms_conditions/2` : "/terms_conditions/2")} >{t("privacy policy")}</p>
                        </div>
                    </div>
                    <div>
                        <div className={styles.footer_upper_right} >
                            <CustomeHeaderH3 style={{ color: "#FFFFFF" }} >{t("contac us")}
                            </CustomeHeaderH3>
                            <div className={styles.footer_menu}>
                                <div className={styles.service_timings} >
                                    <p className={styles.service} style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "Lexend-Light", }} >(+966) 11 249 9050
                                        <a href='mailto:icare@Venderlane.com' className={styles.service_timing} style={{ fontFamily: "Cairo-Medium" }} >icare@Venderlane.com</a></p>
                                </div>
                            </div>
                        </div>
                        <div className={styles.footer_upper_right1} >
                            <div className={styles.shares_icon}>
                                <a href={`http://${termsCondtions?.twitter}`} target="_blank"><img src={twitter} alt="" /></a>
                                <a href={`http://${termsCondtions?.instagram}`} target="_blank"><img src={instagram} alt="" /></a>
                                <a href={`http://${termsCondtions?.facebook}`} target="_blank"><img src={linkedin} alt="" /></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.footer_lower}>
                    <div className={styles.footer_lower_left}>
                        <h6 style={{ fontFamily: laung == "ar" ? "Cairo-Semibold" : "Lexend-Light", }} >{t('Venderlane APP')}</h6>
                        <div className={styles.help_call}>
                            {termsCondtions && <a href={`${termsCondtions?.play_store}`} target="_blank" rel="noopener noreferrer" > <img src={googleplay} alt="X" title='X' /></a>}
                            {termsCondtions && <a href={`${termsCondtions?.app_store}`} target="_blank" rel="noopener noreferrer" > <img src={appstore} alt="X" title='X' /></a>}
                        </div>
                    </div>
                    <div className={styles.footer_lower_right}>
                        <a href="#" onClick={() => setVatModal(true)}><img src={right1} alt="" /></a>
                        <a href="#" onClick={() => setCrModal(true)}><img src={right2} alt="" /></a>
                        <a href="#" onClick={() => setSaudiBusiness(true)}><img src={right3} alt="" /></a>
                    </div>
                </div>
                <p className={styles.venderlane_rights} style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "Lexend-Light", }}>{t("venderlane_rights")}</p>
            </div>
            <Modal
                isOpen={vatModal}
                style={laung == "ar" ? customStyles : customStyles_en}
                onRequestClose={() => setVatModal(false)}
            >
                <img className={styles.close_btn} tyle={{ marginBottom: "10px" }} onClick={() => setVatModal(state => !state)} src={CloseIcon} alt='close' />

                <img src={VatFile} alt="VAT File" style={{ width: "100%", height: "50%" }} />
            </Modal>
            <Modal
                className="modal_second"
                isOpen={CRModal}
                style={laung == "ar" ? customStyles1 : customStyles1_en}
                onRequestClose={() => setCrModal(false)}
            >   <img className={styles.close_btn} style={{ marginBottom: "10px" }} onClick={() => setCrModal(state => !state)} src={CloseIcon} alt='close' />
                <img src={CRFile1} alt="CR File" style={{ width: "100%", height: "50%" }} />
                <img src={CRFile2} alt="CR File" style={{ width: "100%", height: "50%" }} />
                <img src={CRFile3} alt="CR File" style={{ width: "100%", height: "50%" }} />
            </Modal>
            <Modal
                isOpen={SaudiBusiness}
                style={laung == "ar" ? customStyles : customStyles_en}
                onRequestClose={() => setSaudiBusiness(false)}
            >
                <img className={styles.close_btn} tyle={{ marginBottom: "10px" }} onClick={() => setSaudiBusiness(state => !state)} src={CloseIcon} alt='close' />

                <img src={SuadiBusiness} alt="Saudi Business File" style={{ width: "100%", height: "50%" }} />
            </Modal>
        </div>
    )
}

export default LatestFooter;