import React, { Fragment } from 'react';
import styles from "./SellerCard.module.scss";
import Mail from '../../assets/Images/svgs/mail.svg';
import Call from '../../assets/Images/svgs/call.svg';
import Web from '../../assets/Images/svgs/web.svg';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Rating from '@mui/material/Rating';



const SellerCard = ({ vendor }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const laung = (localStorage.getItem('laung'));

  const handleNavigate = (vend) => {
    navigate(`seller_details/${vend.id}`)
  };
  const NavigateToMail = (vendor) => {
    const email = vendor.email_id;
    const mailtoLink = `mailto:${email}`;
    window.location.href = mailtoLink;
  };

  let upDatedURL;
  if (vendor.website.includes("https://")) {
    upDatedURL = vendor.website;
  } else {
    upDatedURL = `https://${vendor.website}`;
  }

  return (
    <div className={styles.container} >
      <div className={styles.seller_border}>
        <div className={styles.container_left} >
          <img
            src={vendor.profile_image}
            alt=""
            className={styles.seller_picture}
            onClick={() => handleNavigate(vendor)}
          />
        </div>
        <div className={styles.container_right} >
          <div className={styles.only_details} onClick={() => handleNavigate(vendor)} >
            <h2 style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "Poppins-Medium" }} >
              {vendor.business_name}
            </h2>
            <h6  style={{ fontFamily: laung == "ar" ? "Cairo-Medium" : "Poppins-Medium" }}>{vendor.sp_category[0]?.title}</h6>
            <p  style={{ fontFamily: laung == "ar" ? "Cairo-Medium" : "Poppins-Medium" }}>{vendor.city}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SellerCard