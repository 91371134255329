import React, { useEffect, useState } from 'react';
import styles from "./faqs.module.scss";
import { H1, H3, P } from '../../constants/CustomText/VendorHeading';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getFaqs } from '../../services/Faq.service';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';


const FAQ = () => {
  const laung = (localStorage.getItem('laung'));
  const [queAns, setQueAns] = useState([]);
  const [firstOpen, setFirstOpen] = useState("0")
  const { t } = useTranslation()

  useEffect(() => {
    getFaqs()
      .then(res => {
        if (res.data.status) {
          setQueAns(res.data.response)
        }
      })
      .catch(err => {
        console.error(err.message, "faq error")
      })
  }, []);

  const hanldeAccordian = (fid) => {
    if (firstOpen != fid) {
      setFirstOpen(fid)
    } else {
      setFirstOpen("")
    }
  }

  const coustomStyles = {
    fontFamily: "Poppins-Regular",
    fontSize: "24px",
    color: "#958559",
    padding: "20px 30px",
    borderRadius: "20px",
    border: "1px solid #9F9F9F"
  }

  return (
    <div className={styles.container}>
      <div className={styles.faqs_body} >
        <Grid container spacing={2} >
          {queAns.length != 0 && queAns.map((data, indi) => {
            return (
              <Grid xs={12} lg={12} key={data.id} >
                <Accordion
                  key={data.id}
                  expanded={indi == firstOpen}
                  style={{ backgroundColor: "#ffffff", marginTop: "20px" }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon fontSize='large' sx={{ color: "#958559" }} />}
                    onClick={() => hanldeAccordian(indi)}
                  >
                    <H3 style={{ color: "#958559" }} >Q. {data.question}</H3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div dangerouslySetInnerHTML={{ __html: data.answer }} style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "avirain_mediam", wordBreak: "break-word", lineHeight: "30px", color: "#00000050" }} />
                  </AccordionDetails>
                </Accordion>
              </Grid>
            )
          })}
          <Accordion
            expanded={"-1" == firstOpen}
            style={{ backgroundColor: "#ffffff", marginTop: "20px", width:"100%", paddingBottom:"20px" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon fontSize='large' sx={{ color: "#958559" }} />}
              onClick={() => hanldeAccordian(-1)}
            >
              <H3 style={{ color: "#958559" }} >{t("contac us")}</H3>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ display: "flex", justifyContent: "center", columnGap: "30px" }}>
                <p style={coustomStyles} >icare@Venderlane.com</p>
                <p style={coustomStyles}>(+966) 11 249 9050</p>
              </div>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </div>
    </div>
  )
}

export default FAQ;