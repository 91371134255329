import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from "./SingleSellerDetails.module.scss";
import { singleSeller } from '../../services/sellerregistration.service';
import { useParams } from 'react-router';
import Mail from '../../assets/Images/svgs/mail.svg';
import Call from '../../assets/Images/svgs/call_component.svg';
import Web from '../../assets/Images/svgs/web.svg';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: '50%',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};


const containerStyle = {
  width: "100%",
  height: '400px',
  borderRadius: "10px"
};

const SingleSellerDetails = () => {

  const { sid } = useParams();
  const { t } = useTranslation();
  const laung = (localStorage.getItem('laung'));


  const [sellerInfo, setSellerInfo] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [displayPic, setDisplayPic] = useState(null);
  const [location, setLocation] = useState(null);
  const [error, setError] = useState(null);

  const [current, setCurrent] = useState({ lat: 22.75543850, lng: 46.20915470 });


  useEffect(() => {
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setLocation({ latitude, longitude });
          },
          (error) => {
            setError(`Error getting location: ${error.message}`);
          }
        );
      } else {
        setError('Geolocation is not supported by your browser.');
      }
    };
    getLocation();
  }, []);

  useEffect(() => {
    singleSeller(sid)
      .then(res => {
        if (res.data.status) {
          setSellerInfo(res.data.response)
          setCurrent({ lat: Number(res.data.response.lat), lng: Number(res.data.response.lng) });
        }
      })
      .catch(e => console.error(e, "erroe"))
  }, []);

  const NavigateToMail = (vendor) => {
    const email = vendor;
    const mailtoLink = `mailto:${email}`;
    window.location.href = mailtoLink;
  };

  const handleClose = () => {
    setOpen(false);
  };

  const showPicture = (picture) => {
    setOpen(true)
    setDisplayPic(picture)
  };

  const handleIframeClick = (pdfurl) => {
    const iframURL = pdfurl
    window.open(iframURL, '_blank');
  };

  const libraries = useMemo(() => {
    return ["places"]
  }, []);


  const { isLoaded } = useJsApiLoader({
    libraries: libraries,
    googleMapsApiKey: "AIzaSyAwhW-oxoIPQo_lbiO5Izr7m2Sal6h51os",
  });

  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(current);
    map.fitBounds(bounds);
  }, [current]);

  const onUnmount = useCallback(function callback(map) {
    // setMap(null)
  }, []);

  const PdfImages = sellerInfo.length !== 0 && sellerInfo.gallery.filter(item => item.image.includes('.pdf'));
  const NonPdfImages = sellerInfo.length !== 0 && sellerInfo.gallery.filter(item => !item.image.includes('.pdf'));


  return (
    <div className={styles.container}>
      <div className={styles.seller_details} >
        {
          sellerInfo &&

          <div className={styles.body_section} >
            <img
              src={sellerInfo.banner}
              alt={sellerInfo.business_name}
              title={sellerInfo.business_name}
              className={styles.seller_picture}
            />
            <div className={styles.name_logo} >
              <div className={styles.name_city} >
                <h1 className={styles.seller_name}
                  style={{ fontFamily: laung == "ar" && "Cairo-Bold" }} >
                  {sellerInfo.business_name}
                </h1>
                <p className={styles.provider_city} style={{ fontFamily: laung == "ar" && "Cairo-Regular" }} >KSA, {sellerInfo.city}</p>
              </div>
              <img
                alt={sellerInfo.business_name}
                title={sellerInfo.business_name}
                className={styles.provider_logo}
                src={sellerInfo.profile_image}
              />
            </div>

            <div className={styles.description} >
              <h2 style={{ fontFamily: laung == "ar" && "Cairo-Semibold" }} >{t("about us")}</h2>
              <p className={styles.description_details} style={{ fontFamily: laung == "ar" && "Cairo-Semibold" }} dangerouslySetInnerHTML={{ __html: sellerInfo.service_description }} />
              {sellerInfo && sellerInfo.sp_category?.length != 0 && <h2 style={{ fontFamily: laung == "ar" && "Cairo-Semibold" }} >{t("Services")}</h2>}
              <div className={styles.product_categories} >
                {
                  sellerInfo && sellerInfo.sp_category?.map((cat, indi) => {
                    return (
                      <p className={styles.category_name} style={{ fontFamily: laung == "ar" && "Cairo-Semibold",textAlign: laung == "ar" ? "right":"left" }} key={indi}>{indi + 1} {cat.title}</p>
                    )
                  })
                }
              </div>
            </div>

            <div className={styles.name_timing} >
              <h2 style={{ fontFamily: laung == "ar" && "Cairo-Semibold" }} >{t("Contact Information")}</h2>

              <div className={styles.seller_contacts} >
                <div className={styles.contact_by} onClick={() => NavigateToMail(sellerInfo.email_id)} >
                  <img
                    src={Mail}
                    alt="Mail"
                  />
                  <p style={{ fontFamily: laung == "ar" && "Cairo-Regular" }}>{sellerInfo.email_id}</p>
                </div>
                <a href={`tel:${sellerInfo.country_code}${sellerInfo.mobile_number}`} >
                  <div className={styles.contact_by} >
                    <img
                      src={Call}
                      alt="Call"
                    />
                    <p style={{ fontFamily: laung == "ar" && "Cairo-Regular" }}>{sellerInfo.country_code}-{sellerInfo.mobile_number}</p>
                  </div>
                </a>
                {sellerInfo.website &&
                  <a href={sellerInfo.website.includes("https://") ? sellerInfo.website : `https://${sellerInfo.website}`} target="_blank" >
                    <div className={styles.contact_by} >
                      <img
                        src={Web}
                        alt="website"
                      />
                      <p style={{ fontFamily: laung == "ar" && "Cairo-Regular" }} >{sellerInfo.website}</p>
                    </div>
                  </a>}
                <div className={styles.contact_by} >
                  <p style={{ fontFamily: laung == "ar" && "Cairo-Regular" }}>{t("Working Hours")}<br /> ( {sellerInfo.open_by} - {sellerInfo.close_by} )</p>
                </div>
              </div>
            </div>

            {isLoaded ?
              <div className={styles.map_details} >
                <h2 style={{ fontFamily: laung == "ar" && "Cairo-Semibold" }} >{t("Map")}</h2>
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={current}
                  zoom={18}
                  onLoad={onLoad}
                  onUnmount={onUnmount}
                  clickableIcons
                >
                  <Marker
                    position={current}
                    draggable={false}
                  />
                </GoogleMap>
              </div> : <></>}

            {sellerInfo && sellerInfo.gallery?.length != 0 && PdfImages.length !== 0 &&
              <div className={styles.photo_details} style={{ Border: "0", boxShadow: "unset", padding: "22px 0" }}>
                {PdfImages.length !== 0 && <h1 style={{ fontFamily: laung == "ar" && "Cairo-Semibold" }} className={styles.doc_heading}  >{t("document view")}</h1>}
                {PdfImages.length !== 0 &&
                  <div className={styles.photos_doc} >
                    {PdfImages && PdfImages.map((pic, indi) => {
                      return (
                        <div key={indi} className={styles.single_ifrm} >
                          <iframe
                            title="PDF Viewer"
                            src={pic.image}
                            onClick={() => handleIframeClick(pic.image)}
                          >
                          </iframe>
                          <p className={styles.download_text}> <a style={{ textAlign: "center" }} href={pic.image} target='_blank'>{t("download file")}</a></p>
                        </div>
                      )
                    })}
                  </div>
                }
              </div>
            }
            {sellerInfo && sellerInfo.gallery?.length != 0 && NonPdfImages.length !== 0 &&
              <div className={styles.photo_details} >
                {NonPdfImages.length !== 0 && <h1 style={{ fontFamily: laung == "ar" && "Cairo-Semibold" }} className={styles.doc_heading} >{t("gallery view")}</h1>}
                {NonPdfImages.length !== 0 &&
                  <div className={styles.photos} >
                    {NonPdfImages && NonPdfImages.map((pic, indi) => {
                      return (
                        <div key={indi} >
                          {pic.image &&
                            <img
                              src={pic.image}
                              alt="Images"
                              title="Product Images"
                              onClick={() => showPicture(pic.image)}
                            />}
                        </div>
                      )
                    })}
                  </div>
                }
              </div>
            }
          </div>
        }
      </div>
      <Modal
        isOpen={open}
        onRequestClose={() => (handleClose())}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <img src={displayPic} style={{ height: "100%", width: "100%" }} />
      </Modal>
    </div>
  )
}

export default SingleSellerDetails;