import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from "./Sidebar.module.scss";
import swal from "sweetalert";
import { getUserDetails } from '../../../services/Profile';
import { useTranslation } from 'react-i18next';
import EvenUser from "../../../assets/Images/pngs/even.png";
import { googleLogout } from '@react-oauth/google';
import ReactModal from "react-modal";
import VenderlaneLogo from "../../../assets/Images/svgs/venderlane_logo_1.svg";
import { useSelector } from 'react-redux';
import Help from "../../../assets/Images/pngs/wsp_icon.png";
import Call from "../../../assets/Images/svgs/call-icon.svg";


const Sidebar = ({
  closeDrawer,
  setDrawer,
  laung
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation()
  const [userDetails, setUserDetails] = useState({});
  const [shippingModal, setShippingModal] = useState(false)

  useEffect(() => {
    getUserDetails()
      .then(res => {
        if (res.data.status) {
          setUserDetails(res.data.response)
        }
      })
      .catch(e => {
        if (e.response.status == "503") {
          navigate(laung == 'en' ? `/${laung}/login` : "/login")
        }
      })
  }, []);

  const handleLogOut = () => {
    swal({
      title: t("are you sure"),
      text: t("logout from venderlane"),
      icon: "warning",
      buttons: [t("no"), t("yes")]
    }).then(confirm => {
      if (confirm) {
        googleLogout()
        let keysToRemove = [
            "venlogtoken", 
            "filteredCities",
            "departmentID",
            "department_title",
            "filteredSubCategories", 
            "filteredBrands", 
            "filterSortingID", 
            "filterCustomerReview", 
            "searchTerm", 
            "minimum_price", 
            "usertab",
            "selectedorderstatus",
            "maximum_price", "browse_vendor"];
        keysToRemove.forEach(k => localStorage.removeItem(k))
        navigate(laung == 'en' ? `/${laung}/login` : "/login")
      }
    })
  };

  const customStyles = {
    content: {
      left: '50%',
      right: '50%',
      bottom: 'auto',
      borderRadius: "20px",
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const termsCondtions = useSelector((state) => state?.termsandPrivacy.data?.response);

  const openWhatsAppChat = (wattsapp_number) => {
    const url = `https://wa.me/${wattsapp_number}?text=${encodeURIComponent("السلام عليكم")}`;
    window.open(url, '_blank');
};
  return (
    <div className={styles.sidebar} >
      <div className={styles.sidebar_category}
        onClick={() => (navigate("my_profile"), closeDrawer())}
      >
        {userDetails && <img src={EvenUser} alt={userDetails.first_name} />}
        {userDetails && <p className={styles.category_name} style={{ fontFamily: laung == "ar" && "Cairo-Bold" }} >{userDetails.first_name} {userDetails.last_name}</p>}
      </div>
      <div className={styles.account_category} >
        <p className={styles.account_label} style={{ fontFamily: laung == "ar" && "Cairo-Bold" }} >{t("Favorites")}</p>
        <p className={styles.acc_sub_cat}
          style={{ fontFamily: laung == "ar" && "Cairo-Regular" }}
          onClick={() => (navigate(laung == "en" ? `/${laung}/wish_list` : "/wish_list"),
            closeDrawer())}
        >
          {t('wishlist')}
        </p>
        <p className={styles.acc_sub_cat} onClick={() => (navigate(laung == "en" ? `/${laung}/recent_viewed` : "/recent_viewed"), closeDrawer())} style={{ fontFamily: laung == "ar" && "Cairo-Regular" }} >{t("Recently Viewed")}</p>
        <p className={styles.acc_sub_cat} onClick={() => (navigate(laung == "en" ? `/${laung}/pending_reviews` : "/pending_reviews"), closeDrawer())} style={{ fontFamily: laung == "ar" && "Cairo-Regular" }}>{t("Review")}</p>
        <p className={styles.acc_sub_cat} onClick={() => (closeDrawer(), setShippingModal(true))} style={{ fontFamily: laung == "ar" && "Cairo-Regular" }} >{t("Customer help")}</p>

      </div>
      <div className={styles.account_category} >
        <p className={styles.account_label} style={{ fontFamily: laung == "ar" && "Cairo-Bold" }}>{t("legal")}</p>
        <p className={styles.acc_sub_cat}
          onClick={() => (navigate(laung == "en" ? `/${laung}/terms_conditions/1` : "/terms_conditions/1"), closeDrawer())}
          
          style={{ fontFamily: laung == "ar" && "Cairo-Regular" }} >
          {t("terms & conditions")}
        </p>
        <p className={styles.acc_sub_cat}
          onClick={() => (navigate(laung == "en" ? `/${laung}/terms_conditions/2` : "/terms_conditions/2"), closeDrawer())}
          style={{ fontFamily: laung == "ar" && "Cairo-Regular" }} >
          {t("privacy policy")}
        </p>
        <p className={styles.acc_sub_cat}
          onClick={() => (navigate(laung == "en" ? `/${laung}/faqs` : "/faqs"), closeDrawer())}
          style={{ fontFamily: laung == "ar" && "Cairo-Regular" }} >
          {t("faq")}
        </p>
        <p className={styles.acc_sub_cat} onClick={() => (handleLogOut(), closeDrawer())} style={{ fontFamily: laung == "ar" && "Cairo-Regular" }} >{t("logout")}</p>
      </div>

      <ReactModal
        isOpen={shippingModal}
        onRequestClose={() => setShippingModal(state => !state)}
        style={customStyles}
      >
        <div className={styles.customer_help_container} >
          <img src={VenderlaneLogo} alt="venderlane" style={{height:"200px", width:"200px"}}/>
            <div className={styles.help_call}>
              {termsCondtions && 
              <p onClick={() => openWhatsAppChat(termsCondtions.whatsapp_call)} className={styles.help} >
                <img src={Help} alt="Help" title="Help" />
                <p className={styles.help_sec} style={{ fontFamily: "Cairo-Medium" }} >{t("quick help")}</p>
              </p>}
              {termsCondtions && <a href={`tel:${termsCondtions.direct_call}`} className={styles.call} >
                <img src={Call} alt="Call" title="Call" />
                <p className={styles.help_sec} style={{ fontFamily: "Cairo-Medium" }}>{t("call us")}</p>
              </a>}
            </div>
        </div>
      </ReactModal>
    </div>
  )
};

export default Sidebar;
